import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import axios from 'axios';
import { SmileOutlined } from '@ant-design/icons';
import Loading from '../../../components/GeneralComponents/Loading';
import moment from 'moment';

const { Meta } = Card;

const Portfolio = () => {
  const [portfolio, setPortfolio] = useState([]);

  const loadPortfolio = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/portfolio`
      );

      const { data } = response.data;
      setPortfolio(data);
    } catch (error) {
      console.log(`Portfolio error on ${error}`);
    }
  };

  useEffect(() => {
    loadPortfolio();
    // eslint-disable-next-line
  }, []);

  return Object.keys(portfolio).length === 0 ? (
    <Loading />
  ) : (
    <section className="w-full min-h-screen text-center bg-fixed">
      <h1 className="text-3xl py-10 lg:px-0 px-4 font-dosis">
        Mereka yang merayakan hari bahagianya bersama kami
      </h1>
      <p></p>

      <div className="lg:grid lg:grid-cols-4 lg:gap-8 lg:place-items-center lg:mx-20 md:mx-10 md:grid md:gap-5 md:place-items-center md:grid-cols-2 mx-5 flex flex-col items-center">
        {portfolio.length > 0 ? (
          portfolio.map((data, i) => (
            <Card
              key={i}
              hoverable
              className="mb-10 w-11/12 shadow"
              cover={
                <img
                  alt={data.couple}
                  className="object-cover h-52"
                  src={`${process.env.REACT_APP_API_URL}/assets/images/portfolio/${data.screenshot}`}
                />
              }
            >
              <small>{moment(data.wedding_date).format('d MMMM YYYY')}</small>
              <Meta title={data.couple} description={data.testimonial} />
              <button className="mt-5">
                <a
                  href={data.url}
                  target="_blank"
                  rel="noreferrer"
                  className="font-dosis text-sm border-[#d9d9d9]/60 shadow-sm border-[1px] py-1 px-4 rounded bg-transparent hover:text-[#4096ff] hover:border-[#4096ff]"
                >
                  Lihat undangan
                </a>
              </button>
            </Card>
          ))
        ) : (
          <h1 className="text-xl lg:text-2xl mt-24 px-5">
            Yuk jadi yang pertama rayakan momen bahagiamu bareng Infinite
            Invites <SmileOutlined />
          </h1>
        )}
      </div>
    </section>
  );
};

export default Portfolio;
