import React from 'react';
import { Icon } from '@iconify/react';
import {
  FadeDown,
  FadeRight,
} from '../../../components/GeneralComponents/Animation';

const Feature = () => {
  return (
    <section
      id="feature"
      className="px-4 py-16 md:px-24 lg:px-8 lg:py-20 bg-fixed text-center font-dosis"
    >
      <FadeDown>
        <div className="mb-8">
          <h1 className="lg:text-5xl md:text-4xl text-3xl text-gray-700 lg:mb-5 mb-3 font-dosis font-semibold">
            Fitur Unggulan
          </h1>
          <p className="">Fitur undangan digital terbaik hanya untukmu</p>
        </div>
      </FadeDown>

      <FadeRight>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:mx-16 md:mx-14 mx-0">
          <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] px-8 py-5 rounded-xl flex flex-col items-center m-3 hover:scale-105 transform transition duration-500">
            <Icon
              className="mb-4 text-3xl font-bold"
              icon="teenyicons:book-outline"
            />
            <h1 className="text-lg font-semibold">Buku Tamu</h1>
            <p>
              Buat kenangan tak terlupakan yang memudahkan tamu Anda untuk
              meninggalkan pesan mereka.
            </p>
          </div>
          <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] px-8 py-5 rounded-xl flex flex-col items-center m-3 hover:scale-105 transform transition duration-500">
            <Icon
              className="mb-4 text-3xl font-bold"
              icon="bi:envelope-paper-heart"
            />
            <h1 className="text-lg font-semibold">Amplop Digital</h1>
            <p>Tamu dapat memberikan amplop langsung secara digital</p>
          </div>
          <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] px-8 py-5 rounded-xl flex flex-col items-center m-3 hover:scale-105 transform transition duration-500">
            <Icon className="mb-4 text-3xl font-bold" icon="arcticons:maps" />
            <h1 className="text-lg font-semibold">Navigasi Lokasi</h1>
            <p>
              Jelajahi tempat acara dengan mudah yang akan membantu tamu Anda
              sampai ke tujuan dengan lancar.
            </p>
          </div>
          <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] px-8 py-5 rounded-xl flex flex-col items-center m-3 hover:scale-105 transform transition duration-500">
            <Icon
              className="mb-4 text-3xl font-bold"
              icon="material-symbols-light:history-edu"
            />
            <h1 className="text-lg font-semibold">Perjalanan Cinta</h1>
            <p>
              Bagikan perjalanan cintamu sampai akhirnya menuju ke jenjang yang
              lebih serius.
            </p>
          </div>
          <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] px-8 py-5 rounded-xl flex flex-col items-center m-3 hover:scale-105 transform transition duration-500">
            <Icon
              className="mb-4 text-3xl font-bold"
              icon="ri:double-quotes-r"
            />
            <h1 className="text-lg font-semibold">Custom quotes</h1>
            <p>
              Tambahkan sentuhan pribadi dengan Custom Quotes yang memungkinkan
              Anda menampilkan kutipan yang menginspirasi dan menggambarkan
              perjalanan cinta Anda.
            </p>
          </div>
          <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] px-8 py-5 rounded-xl flex flex-col items-center m-3 hover:scale-105 transform transition duration-500">
            <Icon
              className="mb-4 text-3xl font-bold"
              icon="pepicons-print:people"
            />
            <h1 className="text-lg font-semibold">Tamu tidak terbatas</h1>
            <p>Undang semua orang yang Anda cintai tanpa batasan.</p>
          </div>
          <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] px-8 py-5 rounded-xl flex flex-col items-center m-3 hover:scale-105 transform transition duration-500">
            <Icon
              className="mb-4 text-3xl font-bold"
              icon="pepicons-print:countdown"
            />
            <h1 className="text-lg font-semibold">Hitung mundur acara</h1>
            <p>
              Mengingatkan Anda dan tamu Anda tentang hari yang ditunggu-tunggu.
            </p>
          </div>
          <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] px-8 py-5 rounded-xl flex flex-col items-center m-3 hover:scale-105 transform transition duration-500">
            <Icon
              className="mb-4 text-3xl font-bold"
              icon="majesticons:music"
            />
            <h1 className="text-lg font-semibold">Latar musik</h1>
            <p>
              Ciptakan suasana yang romantis dengan Latar musik yang dapat Anda
              pilih sesuai dengan tema pernikahan Anda.
            </p>
          </div>
          <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] px-8 py-5 rounded-xl flex flex-col items-center m-3 hover:scale-105 transform transition duration-500">
            <Icon
              className="mb-4 text-3xl font-bold"
              icon="mdi:view-gallery-outline"
            />
            <h1 className="text-lg font-semibold">Foto galeri</h1>
            <p>Bagikan momen indah foto-foto pernikahan Anda.</p>
          </div>
          <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] px-8 py-5 rounded-xl flex flex-col items-center m-3 hover:scale-105 transform transition duration-500">
            <Icon
              className="mb-4 text-3xl font-bold"
              icon="mingcute:video-line"
            />
            <h1 className="text-lg font-semibold">Video Pra-nikah</h1>
            <p>
              Ceritakan kisah cinta Anda dengan lebih hidup melalui fitur Video
              pra-wedding.
            </p>
          </div>
          <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] px-8 py-5 rounded-xl flex flex-col items-center m-3 hover:scale-105 transform transition duration-500">
            <Icon
              className="mb-4 text-3xl font-bold"
              icon="fluent-mdl2:streaming"
            />
            <h1 className="text-lg font-semibold">Live streaming</h1>
            <p>
              Biarkan orang-orang tersayang yang tidak dapat hadir merasakan
              kebahagiaan Anda dengan menyaksikan pernikahan Anda secara
              langsung.
            </p>
          </div>
          <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] px-8 py-5 rounded-xl flex flex-col items-center m-3 hover:scale-105 transform transition duration-500">
            <Icon
              className="mb-4 text-3xl font-bold"
              icon="mdi:report-box-outline"
            />
            <h1 className="text-lg font-semibold">
              Laporan kehadiran tamu dan ucapan
            </h1>
            <p>Pantau kehadiran tamu dan baca ucapan mereka dengan mudah.</p>
          </div>
        </div>
      </FadeRight>
    </section>
  );
};

export default Feature;
