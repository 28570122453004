import React, { forwardRef, useState, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { FadeDown } from '../../../components/GeneralComponents/Animation';

const Countdown = forwardRef((props, ref) => {
  const COUNTDOWN_TARGET = new Date(props.coupleDetails.countdown);
  const getTimeLeft = () => {
    const total = COUNTDOWN_TARGET - new Date();
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((total / (1000 * 60)) % 60);
    const seconds = Math.floor((total / 1000) % 60);

    if (total < 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
    return { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(() => getTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(getTimeLeft());
    }, 1000);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line
  }, [timeLeft]);

  return (
    <FadeDown>
      <div ref={ref}>
        <h1 className="lg:text-4xl text-3xl mb-5">Until we got married</h1>
        <div className="flex">
          {Object.entries(timeLeft).map((el) => {
            const label = el[0];
            const value = el[1];

            return (
              <table key={label}>
                <tbody>
                  <tr className="border border-rose-300 border-t-2">
                    <th className="lg:text-5xl md:text-4xl text-2xl lg:px-10 lg:py-5 md:px-6 md:py-3 px-3 py-2">
                      {!isNaN(value) ? (
                        value
                      ) : (
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                      )}
                    </th>
                  </tr>
                  <tr className="border border-rose-300 border-t-2">
                    <td className="lg:text-xl text-base lg:px-8 lg:py-4 md:px-6 md:py-3 px-3 py-2">
                      {label}
                    </td>
                  </tr>
                </tbody>
              </table>
            );
          })}
        </div>
      </div>
    </FadeDown>
  );
});

export default Countdown;
