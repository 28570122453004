import React from 'react';
import LandingPage from './LandingPage';
import Feature from './Feature';
import Price from './Price';
import QnA from './QnA';
import WhyUs from './WhyUs';

const MainWebsite = () => {
  return (
    <div>
      <LandingPage />
      <Feature />
      <WhyUs />
      <Price />
      <QnA />
    </div>
  );
};

export default MainWebsite;
