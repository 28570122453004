import React from 'react';
import moment from 'moment';
import {
  FadeDown,
  FadeLeft,
  FadeRight,
  FadeUp,
} from '../../../components/GeneralComponents/Animation';

const Hero = ({ coupleDetails, handleClick, guest }) => {
  return (
    <div className="lg:text-lg text-base">
      <FadeDown>
        <h1 className="uppercase mb-10">
          We invite you to <br /> celebrate our wedding
        </h1>
      </FadeDown>
      <div className="font-borel py-10 lg:w-64 md:w-48 w-full">
        <FadeLeft>
          <h1 className="lg:text-7xl text-5xl text-left">
            {coupleDetails.hero_groom_name}
          </h1>
        </FadeLeft>
        <FadeRight>
          <p className="lg:text-6xl text-4xl">&</p>
          <h1 className="lg:text-7xl text-5xl text-right">
            {coupleDetails.hero_bride_name}
          </h1>
        </FadeRight>
      </div>

      <FadeUp>
        <h1>{moment(coupleDetails.countdown).format('MMMM, D YYYY')}</h1>

        <h4 className="mt-8 mb-3">
          Dear, <span className="font-bold">{guest}</span>
        </h4>
        <button
          className="bg-gray-400/70 rounded-lg py-2 px-4"
          onClick={handleClick}
        >
          Open Invitation
        </button>
      </FadeUp>
    </div>
  );
};

export default Hero;
