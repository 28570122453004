import React, { useState, useEffect } from 'react';
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import { Modal, Spin } from 'antd';
import { FadeRight } from './Animation';

const GuestBook = ({ guest, coupleId, language, time }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [greeting, setGreeting] = useState([]);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    greeting: '',
  });
  const [confirmation, setCofirmation] = useState('');
  const [numberOfPeople, setNumberOfPeople] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const COUNTDOWN_TARGET = new Date(time);
  let date = new Date();
  const now = COUNTDOWN_TARGET - date.setDate(date.getDate() + 7);

  //======================
  //   Start of Pagination
  //=======================

  const pageNumbers = [];
  const pageNumberLimit = 4;

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  const handleNextbtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setCurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pageNumbers.length > maxPageNumberLimit) {
    pageIncrementBtn = (
      <li
        className="mx-2 bg-slate-300 lg:p-3 md:p-3 p-[6px] cursor-pointer hover:bg-slate-400 rounded-md"
        onClick={handleNextbtn}
      >
        {' '}
        &hellip;{' '}
      </li>
    );
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = (
      <li
        className="mx-2 bg-slate-300 lg:p-3 md:p-3 p-[6px] cursor-pointer hover:bg-slate-400 rounded-md"
        onClick={handlePrevbtn}
      >
        {' '}
        &hellip;{' '}
      </li>
    );
  }

  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const renderPageNumbers = pageNumbers.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={`mx-2 bg-slate-300 lg:p-3 md:p-3 p-[6px] cursor-pointer hover:bg-slate-400 rounded-md ${
            currentPage === number ? 'bg-slate-400' : null
          }`}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  //======================
  //   End of Pagination
  //=======================

  //======================
  //  Start Load Greeting
  //======================
  const loadGreeting = async (page) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/greetings/guest-book/${coupleId}/?page=${page}&pageSize=5`
      );
      const { data, totalPages } = response.data;
      setGreeting(data);
      setTotalPages(totalPages);
    } catch (error) {
      console.error('greeting error', error);
    }
  };

  const renderData = (data) => {
    if (!data || data.length === 0) {
      return (
        <h3 className="p-8 text-center mb-3 font-dosis">
          {language === 'en'
            ? "Let's give wishes to the couple!:)"
            : 'Yuk kirim ucapan dan doa ke kami:)'}
        </h3>
      );
    }
    return (
      <ul className="font-dosis text-left">
        {data.map((greet, index) => {
          const timeLeft = moment
            .utc(greet.created_at)
            .local()
            .startOf('seconds')
            .fromNow();
          const colorsByStatus = {
            absent: (
              <CloseCircleOutlined
                style={{
                  color: 'red',
                }}
              />
            ),
            present: (
              <CheckCircleOutlined
                style={{
                  color: 'green',
                }}
              />
            ),
            doubt: (
              <InfoCircleOutlined
                style={{
                  color: 'yellow',
                }}
              />
            ),
          };
          return (
            <div
              key={index}
              className="mx-8 py-5 border-b-2 border-gray-400 border-solid"
            >
              <div className="flex">
                <p className="font-semibold text-lg capitalize mr-2">
                  {greet.name}
                </p>
                {colorsByStatus[greet.confirmation]}
              </div>
              <p className="text-base">{greet.greeting}</p>
              <small className="text-xs font-semibold">{timeLeft}</small>
            </div>
          );
        })}
      </ul>
    );
  };

  //======================
  //  End Load Greeting
  //======================

  //======================
  //  Start Handle
  //======================

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (selector, e) => {
    if (selector === 'number_of_people') {
      setNumberOfPeople(e.target.value);
    } else if (selector === 'confirmation') {
      setCofirmation(e.target.value);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const wishesData = {
      name: guest,
      greeting: formData.greeting,
      couple_id: coupleId,
      created_at: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    };
    setIsModalOpen(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/greetings/guest-book`, wishesData)
      .then((response) => {
        const rsvpData = {
          name: guest,
          number_of_people: numberOfPeople,
          confirmation: confirmation,
          couple_id: coupleId,
          greeting_id: response.data.data.greeting_id,
          created_at: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        };
        axios
          .post(`${process.env.REACT_APP_API_URL}/rsvp`, rsvpData)
          .then((response) => {
            setIsSuccess(true);
            setFormData({ greeting: '' });
            setCofirmation('');
            setNumberOfPeople('');
            if (confirmation === 'present') {
              setModalMessage(
                language === 'in'
                  ? 'Ditunggu kedatanganmu ya!:)'
                  : 'See you in our wedding!:)'
              );
            } else if (confirmation === 'doubt') {
              setModalMessage(
                language === 'in'
                  ? 'Semoga kamu bisa dateng:)'
                  : 'Wish you may come:)'
              );
            } else {
              setModalMessage(
                language === 'in'
                  ? 'Semoga kita bisa ketemu dilain waktu ya:)'
                  : 'See you in another time:)'
              );
            }
            loadGreeting(currentPage);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
    setTimeout(() => {
      setIsModalOpen(false);
    }, 1500);
  };

  //======================
  //  End Handle
  //======================

  useEffect(() => {
    loadGreeting(currentPage);
    // eslint-disable-next-line
  }, [currentPage]);

  return (
    <div className="font-dosis lg:w-2/3 md:w-2/3 w-4/5 mx-auto">
      <FadeRight>
        {now > 0 ? (
          <form action="" method="post">
            <div className="flex flex-col mb-5">
              <label className="mb-1 font-semibold" htmlFor="name">
                Name
              </label>
              <input
                className="border-2 border-gray-300 p-2 rounded-lg"
                type="text"
                name="name"
                value={guest}
                onChange={handleChange}
              />
            </div>
            <div className="flex lg:justify-between md:lg:justify-between lg:flex-row md:flex-row flex-col mb-5">
              <div className="flex flex-col w-full lg:mr-2 md:mr-2 mr-0">
                <label className="mb-1 font-semibold" htmlFor="confirmation">
                  {language === 'en'
                    ? 'Attending Confirmation'
                    : 'Konfirmasi Kehadiran'}
                </label>
                <select
                  value={confirmation}
                  onChange={(event) =>
                    handleSelectChange('confirmation', event)
                  }
                  className="border-2 border-gray-300 p-2 rounded-lg"
                  name="confirmation"
                  id="confirmation"
                >
                  <option value="" disabled>
                    {language === 'en'
                      ? 'Select your attending'
                      : 'Pilih kehadiranmu'}
                  </option>
                  <option value="present">
                    {language === 'en' ? 'Present' : 'Datang'}
                  </option>
                  <option value="absent">
                    {language === 'en' ? 'Absent' : 'Tidak datang'}
                  </option>
                  <option value="doubt">
                    {language === 'en' ? 'Doubt' : 'Belum pasti'}
                  </option>
                </select>
              </div>
              <div className="flex flex-col w-full lg:ml-2 md:ml-2 ml-0">
                <label
                  className="mb-1 font-semibold"
                  htmlFor="number_of_people"
                >
                  {language === 'en' ? 'Number of People' : 'Jumlah'}
                </label>
                <select
                  value={numberOfPeople}
                  onChange={(event) =>
                    handleSelectChange('number_of_people', event)
                  }
                  className="border-2 border-gray-300 p-2 rounded-lg"
                  name=""
                  id=""
                >
                  <option value="" disabled>
                    {language === 'en'
                      ? 'Select your number of people'
                      : 'Pilih jumlah'}
                  </option>
                  <option value="0">
                    {language === 'en' ? 'Not Coming' : 'Tidak datang'}
                  </option>
                  <option value="1">
                    {language === 'en' ? '1 person' : '1 orang'}
                  </option>
                  <option value="2">
                    {language === 'en' ? '2 person' : '2 orang'}
                  </option>
                  <option value="3">
                    {language === 'en' ? '3 person' : '3 orang'}
                  </option>
                </select>
              </div>
            </div>
            <div className="flex flex-col">
              <label className="mb-1 font-semibold" htmlFor="greeting">
                {language === 'en' ? 'Wishes' : 'Ucapan'}
              </label>
              <textarea
                className="border-2 border-gray-300 p-2 rounded-lg"
                name="greeting"
                id="greeting"
                cols="20"
                value={formData.greeting}
                onChange={handleChange}
                rows="5"
              ></textarea>
            </div>
            <button
              onClick={onSubmit}
              type="button"
              className="mt-10 bg-gray-900/30 py-3 px-4 rounded-lg"
            >
              {language === 'en' ? 'Submit' : 'Kirim'}
            </button>
          </form>
        ) : (
          <div className="p-8 text-center text-xl font-bold">
            <h1>Mohon maaf, Anda sudah tidak dapat mengirimkan ucapan:)</h1>
          </div>
        )}
      </FadeRight>

      <Modal
        open={isModalOpen}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <div className="text-center">
          {isSuccess ? (
            <h1 className="font-mono">{modalMessage}</h1>
          ) : (
            <Spin tip="Loading" size="large">
              <div className="content" />
            </Spin>
          )}
        </div>
      </Modal>

      <FadeRight>
        {/* List of wishes */}
        <div className="flex flex-col items-center justify-center my-10">
          <div className="lg:w-2/3 md:w-4/5 w-full border-2 border-gray-200 lg:px-4 md:px-3 px-1 py-3 rounded-xl bg-gray-300/50">
            {renderData(greeting)}

            {/* Pagination */}
            <ul
              className={`text-center flex justify-center py-10 ${
                !greeting || greeting.length === 0 ? 'hidden' : null
              }`}
            >
              <li>
                <button
                  onClick={handlePrevbtn}
                  disabled={currentPage === pageNumbers[0] ? true : false}
                  className={`bg-slate-300 lg:p-3 md:p-3 p-[6px] rounded-lg ${
                    currentPage === 1 ? '' : 'hover:bg-slate-400'
                  } `}
                >
                  <DoubleLeftOutlined />
                </button>
              </li>
              {pageDecrementBtn}
              {renderPageNumbers}
              {pageIncrementBtn}
              <li>
                <button
                  onClick={handleNextbtn}
                  disabled={
                    currentPage === pageNumbers[pageNumbers.length - 1]
                      ? true
                      : false
                  }
                  className={`bg-slate-300 lg:p-3 md:p-3 p-[6px] rounded-lg ${
                    currentPage === totalPages ? '' : 'hover:bg-slate-400'
                  } `}
                >
                  <DoubleRightOutlined />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </FadeRight>
    </div>
  );
};

export default GuestBook;
