import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loading from '../../../components/GeneralComponents/Loading';
import { Button, Dropdown, Card } from 'antd';

const { Meta } = Card;

const Catalogue = () => {
  const [themes, setThemes] = useState([]);

  const loadTheme = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/theme`
      );

      const { data } = response.data;
      setThemes(data);
    } catch (error) {
      console.log(`Portfolio error on ${error}`);
    }
  };

  useEffect(() => {
    loadTheme();
    // eslint-disable-next-line
  }, []);

  return Object.keys(themes).length === 0 ? (
    <Loading />
  ) : (
    <section className="w-full min-h-screen text-center bg-fixed font-dosis">
      <h1 className="lg:text-4xl md:text-2xl text-lg py-7 font-bold">
        Katalog
      </h1>
      <p className="mb-10 text-lg">Pilih desain sesuai gayamu</p>
      <div className="lg:grid lg:grid-cols-4 lg:gap-4 lg:place-items-center lg:mx-15 md:mx-10 md:grid md:gap-6 md:place-items-center md:grid-cols-2 gap-4 mx-5 flex flex-col items-center">
        {themes.map((theme, i) => {
          const items = [
            {
              key: '1',
              label: (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${theme.url_demo}?type=basic&to=Nama%20Undangan`}
                >
                  Basic
                </a>
              ),
            },
            {
              key: '2',
              label: (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${theme.url_demo}?type=exclusive&to=Nama%20Undangan`}
                >
                  Exclusive
                </a>
              ),
            },
          ];
          return (
            <Card
              key={i}
              hoverable
              className="mb-10 w-11/12 shadow"
              cover={
                <img
                  className="object-cover h-80"
                  alt={theme.name}
                  src={`${process.env.REACT_APP_API_URL}/assets/images/catalogue/${theme.screenshot}`}
                />
              }
            >
              <Meta title={theme.name} />
              <div className="flex items-center justify-evenly pt-5">
                <Dropdown
                  menu={{ items }}
                  className="font-dosis"
                  placement="bottomRight"
                  arrow
                >
                  <Button>Preview Theme</Button>
                </Dropdown>
                <a
                  href={`https://wa.me/628${process.env.REACT_APP_WA_NUMBER}`}
                  target="blank"
                  className="font-dosis text-sm border-[#d9d9d9]/60 shadow-sm border-[1px] py-1 px-4 rounded bg-transparent hover:text-[#4096ff] hover:border-[#4096ff]"
                >
                  Order now
                </a>
              </div>
            </Card>
          );
        })}
      </div>
    </section>
  );
};

export default Catalogue;
