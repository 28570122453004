import React, { forwardRef } from 'react';
import { FadeUp } from './Animation';

const Quotes = forwardRef((props, ref) => {
  return (
    <FadeUp>
      <div ref={ref}>
        {props.arab ? (
          <div className="text-center lg:p-14 md:p-8 p-0 mx-auto w-4/5 rounded-lg">
            <h2 className="lg:text-3xl md:text-2xl text-xl lg:leading-relaxed md:leading-relaxed leading-loose font-arabNoto mb-8">
              {props.quotes.arabWord}
            </h2>
            <div className="border-b-4 border-solid border-gray-500 lg:w-1/2 mx-auto my-10"></div>
            <p className="lg:text-2xl md:text-lg text-base font-patrick">
              {props.quotes.word}
              <br /> <span className="font-bold">{props.quotes.author}</span>
            </p>
          </div>
        ) : (
          <div className="text-center lg:p-14 p-8 mx-auto w-4/5 rounded-lg">
            <p className="lg:text-2xl md:text-lg text-base font-patrick">
              {props.quotes.word}
              <br /> <span className="font-bold">{props.quotes.author}</span>
            </p>
          </div>
        )}
      </div>
    </FadeUp>
  );
});

export default Quotes;
