import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Customers
import Bride1 from './pages/Customers/jessy-wahyu';

// Themes
import ThemeTwo from './pages/Theme/ThemeTwo';
import ThemeOne from './pages/Theme/ThemeOne';
import ThemeThree from './pages/Theme/ThemeThree';

// Main Website
import LandingPage from './pages/MainWebsite';
import Catalogue from './pages/MainWebsite/Catalogue';
import Portfolio from './pages/MainWebsite/Portfolio';
import Blog from './pages/MainWebsite/Blog';
import Audio from './pages/MainWebsite/Audio';
import Footer from './components/GeneralComponents/Footer';

// Utils
import WithoutNav from './utils/WithoutNav';
import WithNav from './utils/WithNav';
import NotFound from './pages/MainWebsite/404';

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<WithoutNav />}>
          {/* Bride navigation links */}
          <Route path="/jessy-wahyu" element={<Bride1 />} />

          {/* Theme navigation links */}
          <Route path="/pink-blush" element={<ThemeOne />} />
          <Route path="/sakura-sunshine" element={<ThemeTwo />} />
          <Route path="/green-peace" element={<ThemeThree />} />
        </Route>

        {/* Main website navigation links */}
        <Route element={<WithNav />}>
          <Route path="/" element={<LandingPage />} />
          <Route path="/catalogue" element={<Catalogue />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/audio" element={<Audio />} />
        </Route>

        <Route path="/*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
