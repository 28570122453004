import React, { forwardRef, useState, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

const Countdown = forwardRef((props, ref) => {
  const COUNTDOWN_TARGET = new Date(props.coupleDetails.countdown);
  const getTimeLeft = () => {
    const total = COUNTDOWN_TARGET - new Date();
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((total / (1000 * 60)) % 60);
    const seconds = Math.floor((total / 1000) % 60);

    if (total < 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
    return { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(() => getTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(getTimeLeft());
    }, 1000);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line
  }, [timeLeft]);

  return (
    <div ref={ref}>
      <div className="flex">
        {Object.entries(timeLeft).map((el) => {
          const label = el[0];
          const value = el[1];

          return (
            <div key={label}>
              <div className="rounded-lg lg:mx-2 mx-1 bg-gray-300">
                {!isNaN(value) ? (
                  <div>
                    <p className="lg:text-5xl md:text-4xl text-2xl lg:px-10 md:px-6 px-3 py-2">
                      {value}
                    </p>
                    <p className="p-1 text-base">{label}</p>
                  </div>
                ) : (
                  <LoadingOutlined style={{ fontSize: 24 }} spin />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default Countdown;
