import axios from 'axios';
import React, { useState, useEffect } from 'react';

const Story = ({ coupleId }) => {
  const [stories, setStories] = useState([]);

  const loadStory = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/couple/story/${id}`
      );

      const { data } = response.data;
      setStories(data);
    } catch (error) {
      console.log(`Couple story error on ${error}`);
    }
  };

  useEffect(() => {
    loadStory(coupleId);
  }, [coupleId]);

  return (
    <div className="mx-auto lg:px-14 md:px-10 px-5">
      <section className="text-gray-500">
        <div>
          <div className="p-4 mt-4">
            <div className="flex flex-col">
              {stories.length !== 0 ? (
                stories.map((story, index) => (
                  <div
                    key={index}
                    className="flex lg:flex-row md:flex-row flex-col items-center justify-center my-4 w-full"
                  >
                    <div className="lg:w-1/4 md:w-1/3 w-full lg:mb-0 md:mb-0 mb-7">
                      <img
                        alt={story.title}
                        className="object-cover border-2 border-black mx-auto lg:w-40 lg:h-40 md:w-32 md:h-32 w-28 h-28 rounded-full bg-cover bg-center bg-no-repeat"
                        src={`${process.env.REACT_APP_API_URL}/assets/images/${coupleId}/${story.picture}`}
                      />
                    </div>
                    <div className="bg-gray-200 lg:w-3/4 md:w-2/3 w-full p-4 rounded-xl lg:mr-auto">
                      <h1 className="text-xl font-bold underline">
                        {story.title}
                      </h1>
                      <small>{story.timeline}</small>
                      <h1>{story.description}</h1>
                    </div>
                  </div>
                ))
              ) : (
                <div>Loading...</div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Story;
