import React from 'react';
import {
  FadeDown,
  FadeLeft,
  FadeRight,
  FadeUp,
} from '../../../components/GeneralComponents/Animation';

const Hero = ({ coupleDetails, handleClick, guest }) => {
  return (
    <div>
      <FadeDown>
        <h1 className="lg:text-3xl text-2xl font-dosis mb-5">The wedding of</h1>
      </FadeDown>
      <FadeRight>
        <h1 className="lg:text-9xl text-7xl">
          {coupleDetails.hero_groom_name}
        </h1>
        <p className="lg:text-6xl text-4xl">&</p>
      </FadeRight>

      <FadeLeft>
        <h1 className="lg:text-9xl text-7xl">
          {coupleDetails.hero_bride_name}
        </h1>
      </FadeLeft>

      <FadeUp>
        <h4 className="text-lg lg:text-2xl mt-10 font-dosis">
          Dear{' '}
          <span className="capitalize font-black underline"> {guest}, </span>
        </h4>{' '}
        <h4 className="text-lg lg:text-2xl mb-10 font-dosis">
          You are invited to celebrate our wedding
        </h4>{' '}
        <button
          className="bg-gray-300/50 hover:bg-gray-400 rounded-lg px-3 py-3 text-sm font-sans"
          onClick={handleClick}
        >
          Open Invitation
        </button>
      </FadeUp>
    </div>
  );
};

export default Hero;
