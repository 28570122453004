import React from 'react';
import {
  FadeDownLeft,
  FadeDownRight,
} from '../../../components/GeneralComponents/Animation';

const WeddingInfo = ({ type, details, weddingInfo }) => {
  return (
    <div className="">
      <FadeDownLeft>
        <div className="">
          <h1 className="text-4xl font-borel underline">Akad Nikah</h1>
          <div className="text-xl">
            <div className="p-4">
              <p className="">{details.akad_address}</p>
              <p>{weddingInfo.akadTime}</p>
              <p>
                {weddingInfo.akadDay}, {weddingInfo.akadDate}{' '}
                {weddingInfo.akadMonth} {weddingInfo.akadYear}
              </p>
            </div>
          </div>
          <a
            href={details.akad_gmaps_url}
            target="_blank"
            className={`rounded-xl bg-gray-300 p-3  mr-5 text-sm hover:bg-gray-400 ${
              type === 'basic' ? 'hidden' : ''
            }`}
            rel="noopener noreferrer"
          >
            Open maps
          </a>
          <a
            href={`http://instagram.com/${details.bride_ig}`}
            target="_blank"
            className={`rounded-xl bg-gray-300 p-3 ml-5 text-sm hover:bg-gray-400 ${
              type === 'basic' ? 'hidden' : ''
            }`}
            rel="noopener noreferrer"
          >
            Live Streaming Button
          </a>
        </div>
      </FadeDownLeft>

      <FadeDownRight>
        <div className="mt-16">
          <h1 className="text-4xl font-borel underline">Resepsi</h1>
          <div className="text-xl">
            <div className="p-4">
              <p className="">{details.akad_address}</p>
              <p>{weddingInfo.receptionTime}</p>
              <p>
                {weddingInfo.akadDay}, {weddingInfo.akadDate}{' '}
                {weddingInfo.akadMonth} {weddingInfo.akadYear}
              </p>
            </div>
          </div>
          <a
            href={details.akad_gmaps_url}
            target="_blank"
            className={`rounded-xl bg-gray-300 p-3  text-sm hover:bg-gray-400 ${
              type === 'basic' ? 'hidden' : ''
            }`}
            rel="noopener noreferrer"
          >
            Open maps
          </a>
        </div>
      </FadeDownRight>
    </div>
  );
};

export default WeddingInfo;
