import React from 'react';
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { FadeUp } from '../../../components/GeneralComponents/Animation';
import { Badge } from 'antd';

const silverPackage = [
  {
    id: 1,
    icon: (
      <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Masa aktif 2 bulan',
  },
  {
    id: 2,
    icon: (
      <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Custom quotes',
  },
  {
    id: 3,
    icon: (
      <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Tamu tidak terbatas',
  },
  {
    id: 4,
    icon: (
      <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Hitung mundur acara',
  },
  {
    id: 5,
    icon: (
      <CloseCircleOutlined className="text-red-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Navigasi lokasi',
  },
  {
    id: 6,
    icon: (
      <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Amplop Digital',
  },
  {
    id: 7,
    icon: (
      <CloseCircleOutlined className="text-red-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Ucapan pernikahan',
  },
  {
    id: 8,
    icon: (
      <CloseCircleOutlined className="text-red-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'RSVP',
  },
  {
    id: 9,
    icon: (
      <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Latar musik',
  },
  {
    id: 10,
    icon: (
      <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Foto galeri (max. 9 foto)',
  },
  {
    id: 11,
    icon: (
      <CloseCircleOutlined className="text-red-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Video Pre-wedding',
  },
  {
    id: 12,
    icon: (
      <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Tombol live streaming',
  },
  {
    id: 13,
    icon: (
      <CloseCircleOutlined className="text-red-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Perjalanan cinta',
  },
  {
    id: 14,
    icon: (
      <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Protokol kesehatan',
  },
  {
    id: 15,
    icon: (
      <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
    ),
    describe: '1 x revisi',
  },
  // {
  //   id: 16,
  //   icon: (
  //     <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
  //   ),
  //   describe: '4 working days or less of process',
  // },
  // {
  //   id: 17,
  //   icon: (
  //     <CloseCircleOutlined className="text-red-500 flex flex-col justify-center pr-3" />
  //   ),
  //   describe: 'Google calendar reminder',
  // },
  {
    id: 18,
    icon: (
      <CloseCircleOutlined className="text-red-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Laporan RSVP dan ucapan pernikahan',
  },
];

const platinumPackage = [
  {
    id: 1,
    icon: (
      <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Masa aktif 5 bulan',
  },
  {
    id: 2,
    icon: (
      <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Custom quotes',
  },
  {
    id: 3,
    icon: (
      <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Tamu tidak terbatas',
  },
  {
    id: 4,
    icon: (
      <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Hitung mundur acara',
  },
  {
    id: 5,
    icon: (
      <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Navigasi lokasi',
  },
  {
    id: 6,
    icon: (
      <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Amplop digital',
  },
  {
    id: 7,
    icon: (
      <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Ucapan pernikahan',
  },
  {
    id: 8,
    icon: (
      <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'RSVP',
  },
  {
    id: 9,
    icon: (
      <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Latar musik',
  },
  {
    id: 10,
    icon: (
      <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Foto galeri (max. 15 foto)',
  },
  {
    id: 11,
    icon: (
      <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Video Pre-wedding',
  },
  {
    id: 12,
    icon: (
      <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Tombol live streaming ',
  },
  {
    id: 13,
    icon: (
      <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Perjalanan cinta',
  },
  {
    id: 14,
    icon: (
      <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Protokol kesehatan',
  },
  {
    id: 15,
    icon: (
      <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
    ),
    describe: '4 x revisi',
  },
  // {
  //   id: 16,
  //   icon: (
  //     <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
  //   ),
  //   describe: '2 working days or less of process',
  // },
  // {
  //   id: 17,
  //   icon: (
  //     <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
  //   ),
  //   describe: 'Google calendar reminder',
  // },
  {
    id: 18,
    icon: (
      <CheckCircleOutlined className="text-blue-500 flex flex-col justify-center pr-3" />
    ),
    describe: 'Laporan RSVP dan ucapan pernikahan',
  },
];

const Price = () => {
  return (
    <section id="price" className="bg-cover min-h-screen font-dosis">
      <FadeUp>
        <div className="relative w-full h-full">
          <div className="absolute hidden w-full lg:block h-96" />
          <div className="relative px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
              <h2 className="max-w-lg mb-6 font-dosis text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                <span className="relative inline-block">
                  <svg
                    viewBox="0 0 52 24"
                    fill="currentColor"
                    className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-gray-400 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                  >
                    <defs>
                      <pattern
                        id="2c67e949-4a23-49f7-bf27-ca140852cf21"
                        x="0"
                        y="0"
                        width=".135"
                        height=".30"
                      >
                        <circle cx="1" cy="1" r=".7" />
                      </pattern>
                    </defs>
                    <rect
                      fill="url(#2c67e949-4a23-49f7-bf27-ca140852cf21)"
                      width="52"
                      height="24"
                    />
                  </svg>
                  <span className="relative">Harga</span>
                </span>{' '}
                Undangan
              </h2>
              <p className="text-base text-gray-700 md:text-lg">
                Pilih paket undangan sesuai kebutuhanmu
              </p>
            </div>
            <div className="grid max-w-screen-md gap-10 md:grid-cols-2 sm:mx-auto">
              {/* Basic Package */}
              <div>
                <div className="p-8 bg-gray-900/80 rounded">
                  <div className="my-5 text-center">
                    <p className="text-xl font-medium tracking-wide text-white mb-3">
                      Paket Basic
                    </p>
                    <div className="flex flex-col">
                      <p className="line-through text-left text-red-500 text-xl font-sans text-gray-500/70">
                        Rp150.000
                      </p>
                      <p className="mr-2 text-4xl font-semibold text-white lg:text-5xl">
                        Rp60.000
                      </p>
                    </div>
                  </div>
                  <ul className="mb-8 space-y-2">
                    {silverPackage.map((item, i) => {
                      return (
                        <li
                          key={item.id}
                          className="flex gap-x-3 text-base font-medium text-gray-300"
                        >
                          {item.icon}
                          {item.describe}
                        </li>
                      );
                    })}
                  </ul>
                  <button
                    type="submit"
                    className="inline-flex border-slate-100 border-2 hover:text-[#4096ff] hover:border-[#4096ff] items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                  >
                    <a
                      href={`https://wa.me/628${process.env.REACT_APP_WA_NUMBER}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Get Now
                    </a>
                  </button>
                </div>
                <div className="w-11/12 h-2 mx-auto bg-gray-900 rounded-b opacity-75" />
                <div className="w-10/12 h-2 mx-auto bg-gray-900 rounded-b opacity-50" />
                <div className="w-9/12 h-2 mx-auto bg-gray-900 rounded-b opacity-25" />
              </div>

              {/* Exclusive Package */}
              <Badge.Ribbon
                text="Paling Populer"
                className="text-lg mt-2 font-raleway"
                color="red"
              >
                <div className="font-dosis">
                  <div className="p-8 bg-gray-900/80 rounded">
                    <div className="my-5 text-center">
                      <p className="text-xl font-medium tracking-wide text-white mb-3">
                        Paket Exclusive
                      </p>
                      <div className="flex flex-col">
                        <p className="line-through text-left text-red-500 text-xl font-sans text-gray-500/70">
                          Rp250.000
                        </p>
                        <p className="mr-2 text-4xl font-semibold text-white lg:text-5xl">
                          Rp100.000
                        </p>
                      </div>
                    </div>
                    <ul className="mb-8 space-y-2">
                      {platinumPackage.map((item, i) => {
                        return (
                          <li
                            key={item.id}
                            className="flex gap-x-3 text-base font-medium text-gray-300"
                          >
                            {item.icon}
                            {item.describe}
                          </li>
                        );
                      })}
                    </ul>
                    <button
                      type="submit"
                      className="inline-flex border-slate-100 border-2 items-center hover:text-[#4096ff] hover:border-[#4096ff] justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                    >
                      <a
                        href={`https://wa.me/628${process.env.REACT_APP_WA_NUMBER}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Get Now
                      </a>
                    </button>
                  </div>
                  <div className="w-11/12 h-2 mx-auto bg-gray-900 rounded-b opacity-75" />
                  <div className="w-10/12 h-2 mx-auto bg-gray-900 rounded-b opacity-50" />
                  <div className="w-9/12 h-2 mx-auto bg-gray-900 rounded-b opacity-25" />
                </div>
              </Badge.Ribbon>
            </div>
          </div>
        </div>
      </FadeUp>
    </section>
  );
};

export default Price;
