import React from 'react';
import { ClockCircleOutlined, CalendarOutlined } from '@ant-design/icons';
import { ZoomInRight } from '../../../components/GeneralComponents/Animation';

const WeddingInfo = ({ type, details, weddingInfo }) => {
  return (
    <div>
      <ZoomInRight>
        <div className="container mx-auto text-center">
          <h2 className="font-sacramento mx-5 lg:mx-0 lg:text-7xl text-6xl font-extrabold mb-10">
            Informasi Acara
          </h2>
          <h3 className="lg:text-xl text-base lg:px-3 px-8">
            Tanpa mengurangi rasa hormat, dengan ini kami bermaksud mengundang
            Bapak/Ibu/Saudara/i untuk menghadiri acara pernikahan kami yang
            InsyaAllah akan dilaksanakan pada:
          </h3>

          <div className="flex flex-col gap-7 lg:flex-row mt-16 justify-center items-center">
            <div className="w-5/6 md:w-3/5 lg:w-1/3 mb-5 h-max border-solid border-2 border-white rounded-xl bg-gray-400 bg-opacity-60 text-gray-900">
              <h5 className="border-b-2 font-sacramento text-3xl border-white border-solid py-3">
                Akad Nikah
              </h5>
              <div>
                <div className="p-4">
                  <h3 className="font-bold text-lg underline mb-2">
                    {weddingInfo.akadPlace}:
                  </h3>
                  <p className="lg:text-sm text-xs font-semibold">
                    {details.akad_address}
                  </p>
                </div>
                <div className="py-5 flex justify-around lg:flex-row flex-col font-extrabold">
                  <div className="pb-3 lg:pb-0">
                    <ClockCircleOutlined /> <br />
                    <span>{weddingInfo.akadTime}</span>
                  </div>
                  <div>
                    <CalendarOutlined /> <br />
                    <span>
                      {weddingInfo.akadDay}, {weddingInfo.akadDate}{' '}
                      {weddingInfo.akadMonth} {weddingInfo.akadYear}
                    </span>
                  </div>
                </div>
              </div>
              <p className="border-t-2 font-semibold text-sm border-white border-solid py-3">
                {' '}
                Diharapkan kondusif untuk menjaga kekhidmatan
              </p>
            </div>

            <div className="w-5/6 md:w-3/5 lg:w-1/3 mb-5 h-max border-solid border-2 border-white rounded-xl bg-gray-400 bg-opacity-50 text-gray-900">
              <h5 className="border-b-2 font-sacramento text-3xl border-white border-solid py-3">
                Walimah
              </h5>
              <div>
                <div className="p-4">
                  <h3 className="font-bold text-lg underline mb-2">
                    {weddingInfo.receptionPlace}:
                  </h3>
                  <p className="lg:text-sm text-xs font-semibold">
                    {details.akad_address}
                  </p>
                </div>
                <div className="py-5 flex justify-around lg:flex-row flex-col font-extrabold">
                  <div className="pb-3 lg:pb-0">
                    <ClockCircleOutlined /> <br />
                    <span>{weddingInfo.receptionTime}</span>
                  </div>
                  <div>
                    <CalendarOutlined /> <br />
                    <span>
                      {weddingInfo.akadDay}, {weddingInfo.akadDate}{' '}
                      {weddingInfo.akadMonth} {weddingInfo.akadYear}
                    </span>
                  </div>
                </div>
              </div>
              <p className="border-t-2 text-sm font-semibold border-white border-solid py-3">
                {' '}
                Diharapkan kondusif untuk menjaga kekhidmatan
              </p>
            </div>
          </div>

          <iframe
            src={weddingInfo.maps}
            width="75%"
            className={`border-0 mb-8 mx-auto ${
              type === 'basic' ? 'hidden' : ''
            }`}
            height="250"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="maps"
          ></iframe>

          <a
            href={details.akad_gmaps_url}
            target="_blank"
            rel="noopener noreferrer"
            className={`bg-slate-100 hover:bg-gray-900 rounded p-3 font-semibold text-gray-500 ${
              type === 'basic' ? 'hidden' : ''
            }`}
          >
            Klik untuk membuka peta
          </a>
        </div>
      </ZoomInRight>
    </div>
  );
};

export default WeddingInfo;
