import React from 'react';
import { ClockCircleOutlined, CalendarOutlined } from '@ant-design/icons';
import { FadeUp } from '../../../components/GeneralComponents/Animation';

const WeddingInfo = ({ type, details, weddingInfo }) => {
  return (
    <FadeUp>
      <div>
        <h1 className="text-3xl lg:px-0 md:px-0 px-4">
          We invite you to join our wedding
        </h1>
        <div className="flex items-center justify-center my-10 font-dosis">
          <div className="px-8">
            <h1 className="text-xl uppercase font-semibold">Akad Nikah</h1>
            <ClockCircleOutlined className="text-lg mb-2" />
            <p>{weddingInfo.akadTime}</p>
          </div>
          <div className="lg:px-8 md:px-5 px-3 border-x-2 font-bold border-gray-400">
            <h1>{weddingInfo.akadDate}</h1>
            <p>{weddingInfo.akadMonth}</p>
            <h1>{weddingInfo.akadYear}</h1>
          </div>
          <div className="px-8">
            <h1 className="text-xl uppercase font-semibold">
              Wedding Reception
            </h1>
            <CalendarOutlined className="text-lg mb-2" />
            <p>{weddingInfo.receptionTime}</p>
          </div>
        </div>
        <div className="my-10">
          <a
            href={`http://instagram.com/${details.bride_ig}`}
            target="_blank"
            className={`rounded-xl bg-gray-300 p-3 font-dosis text-sm hover:bg-gray-400 ${
              type === 'basic' ? 'hidden' : ''
            }`}
            rel="noopener noreferrer"
          >
            Live Streaming Button
          </a>
        </div>

        <div className="font-dosis">
          <h1 className="text-2xl font-semibold mb-5">Will be held at: </h1>
          <p className="px-5 text-lg mb-5">{details.akad_address}</p>
          <a
            href={details.akad_gmaps_url}
            target="_blank"
            className={`rounded-xl bg-gray-300 p-3 font-dosis text-sm hover:bg-gray-400 ${
              type === 'basic' ? 'hidden' : ''
            }`}
            rel="noopener noreferrer"
          >
            Open maps
          </a>
        </div>
      </div>
    </FadeUp>
  );
};

export default WeddingInfo;
