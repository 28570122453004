import React from 'react';
import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';

const items = [
  {
    key: '1',
    label: 'Bagaimana cara saya melakukan pemesanan?',
    children: (
      <ul className="pl-6">
        <li>1. Pilih tema yang akan digunakan</li>
        <li>2. Anda dapat melihat preview tema dengan mengklik tombol</li>
        <li>3. Hubungi admin via whatsapp</li>
      </ul>
    ),
  },
  {
    key: '2',
    label:
      'Berapa lama waktu pengerjaan tema undangan setelah saya melakukan pemesanan?',
    children: (
      <p
        style={{
          paddingLeft: 24,
        }}
      >
        Setelah melalukan pemesanan dan pembayaran, undangan anda akan siap
        maksimal 2 x 24 jam pada hari kerja. Jika antrian tidak panjang maka
        undangan anda akan siap dalam kurang 1 x 24 jam
      </p>
    ),
  },
  {
    key: '3',
    label:
      'Jika proses antrian pengerjaan membutuhkan waktu 2 hari, apakah saya bisa request untuk dipersingkat?',
    children: (
      <p
        style={{
          paddingLeft: 24,
        }}
      >
        Ya, anda bisa memesan paket ekspress dengan biaya tambahan sebesar Rp.
        50.000 untuk pengerjaan 2 jam
      </p>
    ),
  },
  {
    key: '4',
    label: 'Bagaimana sistem pengerjaan revisi undangan saya?',
    children: (
      <p
        style={{
          paddingLeft: 24,
        }}
      >
        Setelah anda mereview undangan anda, anda dapat memberikan detail
        terkait bagian mana yang harus diperbaiki. Anda dapat melampirkan
        foto/screenshot pada bagian yang akan diperbaiki untuk mempermudah dan
        mempercepat proses revisi. Proses revisi akan memakan waktu kurang dari
        1 x 24 jam
      </p>
    ),
  },
  {
    key: '5',
    label: 'Apakah saya bisa menghilangkan bagian tertentu pada undangan?',
    children: (
      <p
        style={{
          paddingLeft: 24,
        }}
      >
        Ya, anda bisa menghilangkan bagian tertentu yang tidak anda minati atau
        fitur yang tidak anda gunakan seperti Quotes, Love Story, Guest Book,
        Live Streaming, dan lainnya.
      </p>
    ),
  },
  {
    key: '6',
    label: 'Apakah saya bisa menyesuaikan bahasa undangan?',
    children: (
      <p
        style={{
          paddingLeft: 24,
        }}
      >
        Ya, anda dapat merequest bahasa yang digunakan sesuai dengan preferensi
        bahasa anda{' '}
      </p>
    ),
  },
  {
    key: '7',
    label:
      'Apakah saya bisa mengambil bagian dari tema lain untuk ditempelkan pada tema saya?',
    children: (
      <p
        style={{
          paddingLeft: 24,
        }}
      >
        Ya, anda dapat dengan mudah mempersonalisasi undangan Anda untuk
        mencerminkan gaya dan preferensi Anda sendiri.{' '}
      </p>
    ),
  },
];

const QnA = () => {
  return (
    <section
      id="qna"
      className="px-4 py-16 md:px-24 lg:px-8 lg:py-20 bg-fixed flex justify-center flex-col items-center font-dosis"
    >
      <h2 className="max-w-lg mb-6 text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
        Pertanyaan Umum
      </h2>{' '}
      <Collapse
        className="w-4/5 font-dosis text-base"
        items={items}
        bordered={false}
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      />
    </section>
  );
};

export default QnA;
