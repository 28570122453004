import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Hero from './Hero';
import Countdown from './Countdown';
import Couple from './Couple';
import Story from './Story';
import WeddingInfo from './WeddingInfo';
import Gallery from '../../../components/GeneralComponents/Gallery';
import Prevention from '../../../components/GeneralComponents/CoronaPrevention';
import Gifts from '../../../components/GeneralComponents/Gifts';
import Quotes from '../../../components/GeneralComponents/Quotes';
import Music from '../../../components/GeneralComponents/Audio';
import Loading from '../../../components/GeneralComponents/Loading';
import GuestBook from '../../../components/GeneralComponents/GuestBook';
import {
  FadeDown,
  FadeDownLeft,
  FadeDownRight,
  FadeUp,
} from '../../../components/GeneralComponents/Animation';

const ThemeTwo = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');

  const resultRef = useRef(null);
  const [coupleDetails, setCoupleDetails] = useState({});
  const [musicAudio, setMusicAudio] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHidden, setIsHidden] = useState(true);

  const queryParams = new URLSearchParams(window.location.search);
  const guest = queryParams.get('to');
  const rootElement = document.querySelector(':root');

  // ==========================
  // Start Data From Customers
  // ==========================

  const coupleId = 4;
  const audioId = 1;
  const language = 'en';
  const [youtubeID] = useState('SvXXJsFh7L4');

  const giftAccepted = [
    {
      id: 1,
      bank: 'Jago',
      name: 'Peter Parker',
      accountNo: 123456789,
    },
    {
      id: 2,
      bank: 'BSI',
      name: 'Mary Jane Watson',
      accountNo: 987654321,
    },
  ];

  const giftAddress = {
    receiver: 'Mary Jane Watson',
    address: 'Boulevard St No.41, New York',
  };

  const quotes = {
    word: '“In the arithmetic of love, one plus one equals everything, and two minus one equals nothing.”',
    author: '—Mignon McLaughlin',
  };

  const weddingInfo = {
    akadTime: '08.00 - 10.00',
    akadDate: 15,
    akadMonth: 'December',
    akadYear: 2023,
    receptionTime: '13.30 - 18.00',
  };

  // ==========================
  // End Data From Customers
  // ==========================

  // ==========================
  // Start Handle Click
  // ==========================

  const enableScroll = () => {
    window.onscroll = function () {};
    resultRef.current?.scrollIntoView({ behavior: 'smooth' });
    setIsHidden(false);
    setIsPlaying(true);
    musicAudio.play();
  };

  const handlePlay = () => {
    if (isPlaying) {
      musicAudio.pause();
    } else {
      musicAudio.play();
    }
    setIsPlaying(!isPlaying);
  };

  // ==========================
  // End Handle Click
  // ==========================

  // ==========================
  // Start Function
  // ==========================

  const loadAudio = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/audio/${audioId}`
      );

      const { data } = response.data;

      setMusicAudio(
        new Audio(`${process.env.REACT_APP_API_URL}/assets/audio/${data.audio}`)
      );
    } catch (error) {
      console.log(`Audio error on ${error}`);
    }
  };

  const loadCoupleDetails = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/couple/details/${id}`
      );

      const { data } = response.data;
      setCoupleDetails(data);
    } catch (error) {
      console.log(`Couple details error on ${error}`);
    }
  };

  const disableScroll = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
    window.onscroll = function () {
      window.scrollTo(scrollTop, scrollLeft);
    };
    rootElement.style.scrollBehavior = 'auto';
  };

  // ==========================
  // End Function
  // ==========================

  useEffect(() => {
    disableScroll();
    loadCoupleDetails(coupleId);
    loadAudio();
    // eslint-disable-next-line
  }, []);

  return Object.keys(coupleDetails).length === 0 ? (
    <Loading />
  ) : (
    <div>
      {/* Hero */}
      <section className="min-h-screen w-full font-rochester text-center bg-fixed bg-second-theme secondTheme bg-cover bg-center bg-no-repeat flex justify-center items-center">
        <Hero
          coupleDetails={coupleDetails}
          handleClick={() => enableScroll()}
          guest={guest}
        />
      </section>

      {/* Countdown */}
      <section className="h-auto w-full font-dosis text-center bg-cover bg-center bg-no-repeat flex flex-col items-center my-10">
        <Countdown coupleDetails={coupleDetails} ref={resultRef} />
      </section>

      {/* Couple */}
      <section className="min-h-screen w-full font-pacifio text-center bg-cover bg-second-theme secondTheme bg-left bg-fixed bg-no-repeat">
        <Couple details={coupleDetails} />
      </section>

      {/* Wedding Info */}
      <section className="w-full h-auto font-pacifio text-center bg-cover bg-second-theme secondTheme bg-left bg-fixed bg-no-repeat py-14">
        <WeddingInfo
          type={type}
          details={coupleDetails}
          weddingInfo={weddingInfo}
        />
      </section>

      {/* Quotes */}
      <section className="h-auto w-full font-dosis text-center bg-cover bg-center bg-no-repeat flex justify-center my-10">
        <Quotes arab={false} quotes={quotes} />
      </section>

      {/*  Story */}
      <section
        className={`min-h-screen w-full font-pacifio text-center bg-second-theme secondTheme bg-cover bg-left bg-fixed bg-no-repeat pt-24 ${
          type === 'basic' ? 'hidden' : ''
        }`}
      >
        <Story coupleId={coupleId} />
      </section>

      <FadeDown>
        {/* Gallery */}
        <section
          className={`min-h-screen w-full font-pacifio text-center flex flex-col justify-center items-center ${
            type === 'basic'
              ? 'bg-second-theme secondTheme bg-cover bg-left bg-fixed bg-no-repeat'
              : ''
          }`}
        >
          <h3 className="text-center font-raleway pt-10 text-xl lg:text-3xl">
            A glimpse of{' '}
          </h3>
          <h1 className="font-pacifio text-center mx-5 mb-3 lg:mx-0 text-6xl lg:text-7xl font-semibold">
            Our moments
          </h1>
          <Gallery type={type} coupleId={coupleId} youtubeID={youtubeID} />
        </section>
      </FadeDown>

      {/* Guest Book */}
      <section
        className={`min-h-screen w-full font-pacifio text-center bg-second-theme secondTheme bg-cover bg-left bg-fixed bg-no-repeat flex flex-col justify-center items-center ${
          type === 'basic' ? 'hidden' : ''
        }`}
      >
        <FadeDownRight>
          <h1 className="text-4xl my-10">Guest Book</h1>
        </FadeDownRight>
        <GuestBook
          guest={guest}
          coupleId={coupleId}
          language={language}
          time={coupleDetails.countdown}
        />
      </section>

      <FadeDownLeft>
        {/* Gifts */}
        <section className="h-fit w-full font-dosis text-center bg-no-repeat my-16">
          <h1 className="text-4xl mb-10 font-pacifio">Gifts</h1>
          <Gifts giftAccepted={giftAccepted} giftAddress={giftAddress} />
        </section>
      </FadeDownLeft>

      {/* Corona Prevention */}
      <section className="w-full h-auto flex justify-center py-10 bg-second-theme secondTheme bg-cover bg-left bg-fixed bg-no-repeat">
        <FadeUp>
          <Prevention />
        </FadeUp>
      </section>

      <Music
        handlePlay={handlePlay}
        isPlaying={isPlaying}
        isHidden={isHidden}
      />
    </div>
  );
};

export default ThemeTwo;
