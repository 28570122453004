import React from 'react';
import { FadeRight } from '../../../components/GeneralComponents/Animation';
import animationData from '../../../assets/lottieFiles/whyus.json';
import Lottie from 'lottie-react';
import { Icon } from '@iconify/react';

const WhyUs = () => {
  return (
    <section
      id="whyus"
      className="px-4 py-16 md:px-24 lg:px-8 lg:py-20 bg-fixed font-dosis"
    >
      <FadeRight>
        <h1 className="text-center lg:text-5xl md:text-4xl text-3xl text-gray-700 lg:mb-20 mb-16 font-semibold">
          Kenapa memilih kami
        </h1>

        <div className="flex lg:flex-row flex-col justify-evenly">
          <div className="lg:w-2/5 w-1/2	lg:m-auto md:mx-auto mx-auto">
            <Lottie animationData={animationData} />
          </div>
          <div className="lg:w-3/5 md:w-3/4 w-full grid lg:grid-cols-2 grid-cols-1 lg:m-auto md:mx-auto lg:px-0 px-4 text-left">
            <div className="lg:px-8 md:px-5 px-2 py-5 rounded-xl flex lg:justify-center items-center m-3">
              <div className="lg:mr-3 md:mr-5 mr-7">
                <Icon
                  className="mb-4 text-3xl font-bold"
                  icon="ic:outline-auto-awesome"
                />
              </div>
              <div className="flex flex-col items-start">
                <h1 className="text-lg font-semibold">Design yang memukau</h1>
                <p>Design premium hanya untuk anda dan hari bahagia anda.</p>
              </div>
            </div>
            <div className="lg:px-8 md:px-5 px-2 py-5 rounded-xl flex lg:justify-center items-center m-3">
              <div className="lg:mr-3 md:mr-5 mr-7">
                <Icon
                  className="mb-4 text-3xl font-bold"
                  icon="mdi:responsive"
                />
              </div>
              <div className="flex flex-col items-start">
                <h1 className="text-lg font-semibold">
                  Responsive di semua perangkat
                </h1>
                <p>
                  Nikmati kemudahan akses ke undangan pernikahan Anda di semua
                  perangkat dengan tampilan yang responsif.
                </p>
              </div>
            </div>
            <div className="lg:px-8 md:px-5 px-2 py-5 rounded-xl flex lg:justify-center items-center m-3">
              <div className="lg:mr-3 md:mr-5 mr-7">
                <Icon className="mb-4 text-3xl font-bold" icon="iconoir:star" />
              </div>
              <div className="flex flex-col items-start">
                <h1 className="text-lg font-semibold">Fitur Lengkap</h1>
                <p>
                  Dapatkan pengalaman pernikahan yang lengkap dengan fitur-fitur
                  unggulan yang kami tawarkan.
                </p>
              </div>
            </div>
            <div className="lg:px-8 md:px-5 px-2 py-5 rounded-xl flex lg:justify-center items-center m-3">
              <div className="lg:mr-3 md:mr-5 mr-7">
                <Icon
                  className="mb-4 text-3xl font-bold"
                  icon="mdi:customer-service"
                />
              </div>
              <div className="flex flex-col items-start">
                <h1 className="text-lg font-semibold">Service Terbaik</h1>
                <p>Dukungan pelanggan terbaik kami akan siap membantu Anda.</p>
              </div>
            </div>
            <div className="lg:px-8 md:px-5 px-2 py-5 rounded-xl flex lg:justify-center items-center m-3">
              <div className="lg:mr-3 md:mr-5 mr-7">
                <Icon
                  className="mb-4 text-3xl font-bold"
                  icon="solar:tag-price-bold-duotone"
                />
              </div>
              <div className="flex flex-col items-start">
                <h1 className="text-lg font-semibold">Harga Terjangkau</h1>
                <p>
                  Rasakan kualitas tanpa harus menguras kantong Anda dengan
                  harga terjangkau yang kami tawarkan.
                </p>
              </div>
            </div>
          </div>
        </div>
      </FadeRight>
    </section>
  );
};

export default WhyUs;
