import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loading from '../../../components/GeneralComponents/Loading';

const Audio = () => {
  const [audios, setAudios] = useState([]);

  const loadAudios = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/audio`
      );

      const { data } = response.data;
      setAudios(data);
    } catch (error) {
      console.log(`Portfolio error on ${error}`);
    }
  };

  useEffect(() => {
    loadAudios();
    // eslint-disable-next-line
  }, []);

  return Object.keys(audios).length === 0 ? (
    <Loading />
  ) : (
    <div className="w-full">
      {audios.map((data, i) => (
        <div className="" key={i}>
          <h5>{data.title}</h5>
          <audio
            src={`${process.env.REACT_APP_API_URL}/assets/audio/${data.audio}`}
            controls
          />
        </div>
      ))}
    </div>
  );
};

export default Audio;
