import React from 'react';
import { PlayCircleOutlined, PauseCircleOutlined } from '@ant-design/icons';

const Audio = ({ handlePlay, isPlaying, isHidden }) => {
  return (
    <div className={`${isHidden ? 'hidden' : ''} z-10 relative`}>
      <PlayCircleOutlined
        onClick={handlePlay}
        className={`animate-spin-slow ${
          isPlaying ? 'hidden' : ''
        } w-10 h-10 text-5xl fixed bottom-8 right-8 cursor-pointer opacity-60 mix-blend-difference origin-center flex justify-center items-center`}
      />
      <PauseCircleOutlined
        onClick={handlePlay}
        className={`animate-spin-slow  ${
          isPlaying ? '' : 'hidden'
        } w-10 h-10 text-5xl fixed bottom-8 right-8 cursor-pointer opacity-60 mix-blend-difference origin-center flex justify-center items-center`}
      />
    </div>
  );
};

export default Audio;
