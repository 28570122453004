import React from 'react';
import Lottie from 'lottie-react';
import animationData from '../../../assets/lottieFiles/invitation.json';
import { FadeLeft } from '../../../components/GeneralComponents/Animation';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  return (
    <section id="home" className="px-4 py-16 md:px-24 lg:px-8 lg:py-20">
      <FadeLeft>
        <div className="flex lg:flex-row flex-col-reverse justify-evenly">
          <div className="lg:w-2/5 md:w-3/4 w-full lg:m-auto md:mx-auto lg:px-0 px-10 text-center lg:text-right">
            <h1 className="lg:text-4xl md:text-3xl text-xl lg:leading-snug font-dosis font-semibold">
              Rangkul{' '}
              <span className="animate-pulse bg-gradient-to-r from-indigo-400 from-10% via-sky-500 via-30% to-emerald-500 to-90% bg-clip-text text-transparent font-bold">
                Kegembiraan
              </span>
              , Abadikan{' '}
              <span className="animate-pulse bg-gradient-to-r from-indigo-400 from-10% via-sky-500 via-30% to-emerald-500 to-90% bg-clip-text text-transparent font-bold">
                Perjalanan Kehidupan
              </span>{' '}
              dan Rayakan{' '}
              <span className="animate-pulse bg-gradient-to-r from-indigo-400 from-10% via-sky-500 via-30% to-emerald-500 to-90% bg-clip-text text-transparent font-bold">
                Momennya
              </span>
            </h1>

            <div className="flex flex-col lg:flex-row mt-10 items-center">
              <button className="font-bold text-sm hover:text-gray-600 text-white bg-gradient-to-r from-indigo-300 from-10% via-sky-500 via-30% to-emerald-500 to-90% w-2/3 rounded-3xl px-3 py-2 lg:mb-0 lg:mr-5 mb-5">
                <Link to="/catalogue">Lihat Katalog</Link>
              </button>
              <button className="font-bold hover:text-gray-600 text-white bg-gradient-to-r from-indigo-300 from-10% via-sky-500 via-30% to-emerald-500 to-90% w-2/3 rounded-3xl px-3 py-2">
                <a
                  href={`https://wa.me/628${process.env.REACT_APP_WA_NUMBER}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Konsultasi Gratis
                </a>
              </button>
            </div>
          </div>
          <div className="lg:w-2/5 md:w-1/2 w-1/2	lg:m-auto md:mx-auto mx-auto">
            <Lottie animationData={animationData} />
          </div>
        </div>
      </FadeLeft>
    </section>
  );
};

export default LandingPage;
