import React from 'react';
import Logo from '../../assets/images/logo.png';
import { NavLink as LinkRouter } from 'react-router-dom';
import { Link as LinkScroll } from 'react-scroll';
import { CloseOutlined } from '@ant-design/icons';

const Sidebar = ({ isOpen, setIsOpen, goToPageAndScroll, path, location }) => {
  return (
    <aside
      className={`fixed top-0 left-0 w-full min-h-screen ease-in duration-300 bg-[#d9d9d9] z-10 -translate-y-full	-translate-x-full	 ${
        isOpen ? 'translate-x-0 translate-y-0' : ''
      }`}
    >
      <div className="flex justify-between">
        <LinkRouter to="/">
          <img src={Logo} className="w-20 h-14 ml-7 mt-3" alt="" />
        </LinkRouter>

        <button className="mr-10" onClick={() => setIsOpen(!isOpen)}>
          <CloseOutlined />
        </button>
      </div>
      {/* Nav Links */}
      <ul className="mt-5">
        {location !== 'portfolio' && location !== 'catalogue' ? (
          <>
            <li className="my-2 ml-7 link-underline link-underline-black text-black font-semibold cursor-pointer">
              {' '}
              <LinkScroll
                to="home"
                spy={true}
                smooth={true}
                offset={-75}
                duration={500}
              >
                <button onClick={() => setIsOpen(!isOpen)}>Home</button>
              </LinkScroll>
            </li>
            <li className="my-2 ml-7 link-underline link-underline-black text-black font-semibold cursor-pointer">
              <LinkScroll
                to="whyus"
                spy={true}
                smooth={true}
                offset={-75}
                duration={500}
              >
                <button onClick={() => setIsOpen(!isOpen)}>Kenapa Kami</button>
              </LinkScroll>
            </li>
            <li className="my-2 ml-7 link-underline link-underline-black text-black font-semibold cursor-pointer">
              <LinkScroll
                to="feature"
                spy={true}
                smooth={true}
                offset={-75}
                duration={500}
              >
                <button onClick={() => setIsOpen(!isOpen)}>Fitur</button>
              </LinkScroll>
            </li>
            <li className="my-2 ml-7 link-underline link-underline-black text-black font-semibold cursor-pointer">
              <LinkScroll
                to="price"
                spy={true}
                smooth={true}
                offset={-75}
                duration={500}
              >
                <button onClick={() => setIsOpen(!isOpen)}>Harga</button>
              </LinkScroll>
            </li>
            <li
              onClick={() => setIsOpen(!isOpen)}
              className="my-2 ml-7 link-underline link-underline-black text-black font-semibold cursor-pointer"
            >
              <LinkRouter to="/catalogue">Katalog</LinkRouter>
            </li>
            <li
              onClick={() => setIsOpen(!isOpen)}
              className="my-2 ml-7 link-underline link-underline-black text-black font-semibold cursor-pointer"
            >
              <LinkRouter to="/portfolio">Portofolio</LinkRouter>
            </li>
          </>
        ) : (
          <>
            <li className="my-2 ml-7 link-underline link-underline-black text-black font-semibold cursor-pointer">
              {' '}
              <button
                onClick={() => {
                  goToPageAndScroll('home');
                  setIsOpen(!isOpen);
                }}
              >
                Home
              </button>
            </li>
            <li className="my-2 ml-7 link-underline link-underline-black text-black font-semibold cursor-pointer">
              <button
                onClick={() => {
                  goToPageAndScroll('whyus');
                  setIsOpen(!isOpen);
                }}
              >
                {' '}
                Kenapa Kami
              </button>
            </li>
            <li className="my-2 ml-7 link-underline link-underline-black text-black font-semibold cursor-pointer">
              <button
                onClick={() => {
                  goToPageAndScroll('feature');
                  setIsOpen(!isOpen);
                }}
              >
                {' '}
                Fitur
              </button>
            </li>
            <li className="my-2 ml-7 link-underline link-underline-black text-black font-semibold cursor-pointer">
              <button
                onClick={() => {
                  goToPageAndScroll('price');
                  setIsOpen(!isOpen);
                }}
              >
                {' '}
                Harga
              </button>
            </li>
            <li
              onClick={() => setIsOpen(!isOpen)}
              className="my-2 ml-7 link-underline link-underline-black text-black font-semibold cursor-pointer"
            >
              <LinkRouter to="/catalogue">Katalog</LinkRouter>
            </li>
            <li
              onClick={() => setIsOpen(!isOpen)}
              className="my-2 ml-7 link-underline link-underline-black text-black font-semibold cursor-pointer"
            >
              <LinkRouter to="/portfolio">Portofolio</LinkRouter>
            </li>
          </>
        )}
      </ul>
    </aside>
  );
};

export default Sidebar;
