import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {
  FadeDown,
  FadeLeft,
  FadeRight,
} from '../../../components/GeneralComponents/Animation';

const Story = ({ coupleId }) => {
  const [stories, setStories] = useState([]);

  const loadStory = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/couple/story/${id}`
      );

      const { data } = response.data;
      setStories(data);
    } catch (error) {
      console.log(`Couple story error on ${error}`);
    }
  };

  useEffect(() => {
    loadStory(coupleId);
  }, [coupleId]);

  return (
    <div>
      <FadeDown>
        <h1 className="text-4xl mb-5">Our story</h1>
      </FadeDown>
      <div className="w-3/4 mx-auto py-16">
        {stories.length !== 0 ? (
          stories.map((story, index) => (
            <div key={index}>
              {index % 2 === 0 ? (
                <FadeRight>
                  <div className="lg:grid lg:grid-cols-2 md:grid md:grid-cols-2 flex flex-col-reverse">
                    <div className="font-dosis flex flex-col justify-center lg:px-5 md:px-5 px-0 lg:mb-0 md:mb-0 mb-8">
                      <h1 className="font-semibold text-xl mb-3 lg:mt-0 md:mt-0 mt-5 text-center">
                        {story.title}
                      </h1>
                      <p className="">{story.description}</p>
                    </div>
                    <img
                      src={`${process.env.REACT_APP_API_URL}/assets/images/${story.couple_id}/${story.picture}`}
                      className="w-full h-64 object-cover float-left lg:rounded-none md:rounded-none rounded-lg"
                      alt=""
                    />
                  </div>
                </FadeRight>
              ) : (
                <FadeLeft>
                  <div className="lg:grid lg:grid-cols-2 md:grid md:grid-cols-2 flex flex-col">
                    <img
                      src={`${process.env.REACT_APP_API_URL}/assets/images/${story.couple_id}/${story.picture}`}
                      className="w-full h-64 object-cover float-left lg:rounded-none md:rounded-none rounded-lg"
                      alt=""
                    />
                    <div className="font-dosis flex flex-col justify-center lg:px-5 md:px-5 px-0 lg:mb-0 md:mb-0 mb-8">
                      <h1 className="font-semibold text-xl mb-3 lg:mt-0 md:mt-0 mt-5 text-center">
                        {story.title}
                      </h1>
                      <p className="">{story.description}</p>
                    </div>
                  </div>
                </FadeLeft>
              )}
            </div>
          ))
        ) : (
          <div className="">Loading ...</div>
        )}
      </div>
    </div>
  );
};

export default Story;
