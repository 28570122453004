import React, { useState } from 'react';
import {
  CopyOutlined,
  HomeOutlined,
  FileDoneOutlined,
} from '@ant-design/icons';
import BNI from '../../assets/images/banks/bni.png';
import BRI from '../../assets/images/banks/bri.png';
import BSI from '../../assets/images/banks/bsi.png';
import BTN from '../../assets/images/banks/btn.png';
import CIMB from '../../assets/images/banks/cimb.png';
import Gopay from '../../assets/images/banks/gopay.png';
import Jago from '../../assets/images/banks/jago.png';
import Jenius from '../../assets/images/banks/jenius.png';
import Mega from '../../assets/images/banks/mega.png';
import Permata from '../../assets/images/banks/permata.png';
import ShopeePay from '../../assets/images/banks/shopeepay.png';
import BCA from '../../assets/images/banks/bca.png';
import Mandiri from '../../assets/images/banks/mandiri.png';

const Gifts = ({ giftAccepted, giftAddress, language }) => {
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipboard = (account) => {
    navigator.clipboard
      .writeText(account)
      .then(() => {
        setCopySuccess(account);
        setTimeout(function () {
          setCopySuccess('');
        }, 1000);
      })
      .catch((error) => console.error('Failed to copy to clipboard:', error));
  };

  const bankLogo = (key) => {
    switch (key) {
      case 'BCA':
        return BCA;
      case 'Mandiri':
        return Mandiri;
      case 'BNI':
        return BNI;
      case 'BRI':
        return BRI;
      case 'BSI':
        return BSI;
      case 'BTN':
        return BTN;
      case 'CIMB':
        return CIMB;
      case 'Gopay':
        return Gopay;
      case 'Jago':
        return Jago;
      case 'Jenius':
        return Jenius;
      case 'Mega':
        return Mega;
      case 'Permata':
        return Permata;
      case 'ShopeePay':
        return ShopeePay;
      default:
        break;
    }
  };
  return (
    <div>
      <div className="flex lg:justify-evenly lg:flex-row flex-col items-center">
        {giftAccepted.map((gift, i) => {
          return (
            <div
              key={i}
              className="px-7 py-3 mb-10 lg:w-1/4 md:w-2/5 w-3/4 border bg-gray-100 bg-opacity-60 shadow-[0_3px_10px_rgb(0,0,0,0.2)] border-gray-300 rounded-xl"
            >
              <div className="flex justify-end mb-10">
                <img className="w-20" src={bankLogo(gift.bank)} alt="" />
              </div>
              <div className="flex justify-around">
                <div className="uppercase font-bold lg:text-base text-xs">
                  <p className="border-b-2 border-gray-300">{gift.accountNo}</p>
                  <p>{gift.name}</p>
                </div>
                <button
                  onClick={() => copyToClipboard(gift.accountNo)}
                  className="text-2xl p-2 bg-slate-400 bg-opacity-70 cursor-pointer ml-5 rounded-md hover:bg-slate-500"
                >
                  {copySuccess === gift.accountNo ? (
                    <FileDoneOutlined />
                  ) : (
                    <CopyOutlined />
                  )}
                </button>
              </div>
            </div>
          );
        })}
      </div>

      <div className="px-7 text-center py-5 mx-auto lg:w-1/2 w-3/4 border bg-gray-100 bg-opacity-60 shadow-[0_3px_10px_rgb(0,0,0,0.2)] border-gray-300 rounded-xl">
        <HomeOutlined className="lg:text-3xl text-2xl" />
        <h1 className="lg:text-2xl text-lg font-bold mb-5 underline">
          {language === 'en' ? 'Send gift' : 'Kirim hadiah'}
        </h1>
        <p className="lg:text-lg text-sm font-semibold">
          {giftAddress.address}
        </p>
        <p className="lg:text-lg text-base font-semibold">
          {language === 'en' ? 'Receiver' : 'Penerima'} :{' '}
          <span className="font-extrabold">{giftAddress.receiver}</span>
        </p>
      </div>
    </div>
  );
};

export default Gifts;
