import React from 'react';
import { HeartOutlined, InstagramOutlined } from '@ant-design/icons';
import { FadeUp } from '../../../components/GeneralComponents/Animation';

const Bride = ({ details }) => {
  return (
    <div>
      <FadeUp>
        <div className="container mx-auto text-center">
          <h2 className="font-sacramento mx-5 lg:mx-0 text-5xl mb-5 md:text-6xl lg:text-7xl font-extrabold">
            Acara Pernikahan
          </h2>
          <h3 className="text-base lg:text-xl m-6 lg:mb-4 font-medium">
            Assalamu'alaikum warahmatullahi wabarakatuh
          </h3>
          <p className="text-xs m-6 lg:text-lg font-medium">
            Dengan memohon rahmat dan ridho Allah Subhanahu Wa Ta'ala,
            Perkenalkanlah kami berdua bernama:
          </p>
          <div className="lg:flex lg:flex-row  mt-10">
            <div className="basis-11/12 flex flex-row items-center lg:pb-0 md:pb-0 pb-10 lg:mr-10 md:mr-6 mr-3">
              <div className="columns-2xl text-right px-3">
                <h3 className="lg:text-5xl text-2xl font-sacramento font-bold">
                  {details.bride_full_name}
                </h3>
                <div className="flex">
                  <div className="w-1/3"></div>
                  <div className="w-2/3">
                    <p className="text-xs mb-4 font-semibold py-1">
                      <InstagramOutlined />{' '}
                      <span>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`http://instagram.com/${details.bride_ig}`}
                        >
                          @{details.bride_ig}
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
                <p className="lg:text-lg md:text-sm text-xs font-bold">
                  Putri dari Bapak {details.bride_dad} <br />
                  dan <br />
                  Ibu {details.bride_mom}
                </p>
              </div>
              <img
                src={`${process.env.REACT_APP_API_URL}/assets/images/${details.couple_id}/${details.bride_photo}`}
                alt="Mempelai Wanita"
                className="object-cover rounded-xl lg:h-96 h-52 lg:w-2/5 md:w-36 w-36"
              />
            </div>
            <div className="basis-1/12 rounded-full -translate-x-2/4 lg:translate-y-16 xl:translate-y-32 inset-x-1/2 bg-white shadow-3xl lg:visible invisible w-12 h-12 flex absolute">
              <HeartOutlined
                style={{ color: '#ec47a4', margin: 'auto', fontSize: '32px' }}
              />
            </div>
            <div className="basis-11/12 flex flex-row-reverse items-center lg:ml-10 md:ml-6 ml-3">
              <div className="columns-2xl text-left px-3">
                <h3 className="lg:text-5xl text-2xl font-sacramento font-bold">
                  {details.groom_full_name}
                </h3>
                <div className="flex">
                  <div className="w-5/6">
                    <p className="text-xs mb-4 font-semibold py-1">
                      <InstagramOutlined />{' '}
                      <span>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`http://instagram.com/${details.groom_ig}`}
                        >
                          @{details.groom_ig}
                        </a>
                      </span>
                    </p>
                  </div>
                  <div className="w-1/6"></div>
                </div>
                <p className="lg:text-lg md:text-sm text-xs font-bold">
                  Putra dari Bapak {details.groom_dad} <br />
                  dan <br /> {details.groom_mom}
                </p>
              </div>
              <img
                src={`${process.env.REACT_APP_API_URL}/assets/images/${details.couple_id}/${details.groom_photo}`}
                alt="Mempelai Laki-laki"
                className="object-cover rounded-xl lg:h-96 h-52 lg:w-2/5 md:w-36 w-36"
              />
            </div>
          </div>
        </div>
      </FadeUp>
    </div>
  );
};

export default Bride;
