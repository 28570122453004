import React from 'react';
import Countdown from './Countdown';
import { ZoomIn } from '../../../components/GeneralComponents/Animation';

const Hero = ({ guest, handleClick, coupleDetails, language }) => {
  return (
    <div>
      <ZoomIn>
        <main>
          <h1 className="font-sacramento font-extrabold text-4xl md:text-6xl lg:text-8xl">
            {coupleDetails.hero_bride_name} & {coupleDetails.hero_groom_name}
          </h1>{' '}
          <br />
          <p className="text-sm lg:text-xl">
            Akan melangsungkan resepsi pernikahan dalam:
          </p>{' '}
          <Countdown time={coupleDetails.countdown} language={language} />
          <br />
          <h4 className="drop-shadow-lg text-lg lg:text-2xl">
            Kepada{' '}
            <span className="capitalize font-black underline"> {guest}, </span>
          </h4>{' '}
          <br />
          <button
            onClick={handleClick}
            className=" bg-gray-500 bg-opacity-80 text-white hover:bg-gray-700 rounded-xl p-4 font-bold"
          >
            Lihat Undangan{' '}
          </button>
        </main>
      </ZoomIn>
    </div>
  );
};

export default Hero;
