import React, { forwardRef } from 'react';
import { InstagramOutlined } from '@ant-design/icons';
import {
  FadeLeft,
  FadeRight,
} from '../../../components/GeneralComponents/Animation';

const Couple = forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center">
        <div className="lg:pr-5 lg:w-1/3 md:w-72 w-60">
          <FadeRight>
            <img
              src={`${process.env.REACT_APP_API_URL}/assets/images/${props.details.couple_id}/${props.details.bride_photo}`}
              className="object-cover rounded-full h-96"
              alt="bride"
            />
            <h1 className="text-3xl mt-5 ">{props.details.bride_full_name}</h1>
            <button className="mt-2 rounded-xl bg-gray-200 p-3  text-sm hover:bg-gray-400">
              <InstagramOutlined />
              <a
                href={`http:instagram.com/${props.details.bride_ig}`}
                target="_blank"
                rel="noopener noreferrer"
                className="ml-1"
              >
                @{props.details.bride_ig}
              </a>
            </button>
            <h1 className="text-xl  mt-5">
              First Daughter of {props.details.bride_dad} <br /> and <br />{' '}
              {props.details.bride_mom}
            </h1>
          </FadeRight>
        </div>

        <p className="lg:hidden md:hidden font-rochester text-5xl my-8">&</p>

        <div className="lg:pl-5 lg:w-1/3 md:w-72 w-60">
          <FadeLeft>
            <img
              src={`${process.env.REACT_APP_API_URL}/assets/images/${props.details.couple_id}/${props.details.groom_photo}`}
              className="object-cover rounded-full h-96"
              alt="groom"
            />

            <h1 className="text-3xl mt-5">{props.details.groom_full_name}</h1>
            <button className="mt-2 rounded-xl bg-gray-200 p-3  text-sm hover:bg-gray-400">
              <InstagramOutlined />
              <a
                href={`http:instagram.com/${props.details.groom_ig}`}
                target="_blank"
                rel="noopener noreferrer"
                className="ml-1"
              >
                @{props.details.groom_ig}
              </a>
            </button>
            <h1 className="text-xl  mt-5">
              First son of {props.details.groom_dad} <br /> and <br />{' '}
              {props.details.groom_mom}
            </h1>
          </FadeLeft>
        </div>
      </div>
    </div>
  );
});

export default Couple;
