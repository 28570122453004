import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Image } from 'antd';
import { FadeUp } from './Animation';

const Gallery = ({ type, coupleId, youtubeID }) => {
  const [datas, setDatas] = useState([]);

  const loadGallery = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/couple/gallery/${id}`
      );

      const { data } = response.data;

      setDatas(data);
    } catch (error) {
      console.log(`Couple details error on ${error}`);
    }
  };

  useEffect(() => {
    loadGallery(coupleId);
  }, [coupleId]);

  return (
    <div>
      <FadeUp>
        <div
          className={`${youtubeID ? '' : 'hidden'} ${
            type === 'basic' ? '' : 'py-10'
          } flex justify-center`}
        >
          <iframe
            className={`lg:w-3/4 w-11/12 aspect-video ${
              type === 'basic' ? 'hidden' : ''
            }`}
            title="Youtube player"
            sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
            src={`https://youtube.com/embed/${youtubeID}?autoplay=1`}
          ></iframe>
        </div>
      </FadeUp>

      <div className="flex flex-wrap lg:mx-16 md:mx-14 mx-0">
        <Image.PreviewGroup
          preview={{
            onChange: (current, prev) =>
              console.log(`current index: ${current}, prev index: ${prev}`),
          }}
        >
          {datas ? (
            datas.map((data, i) => (
              <div className="w-full md:w-1/2 lg:w-1/3 p-4" key={i}>
                <FadeUp>
                  <Image
                    className="w-full rounded aspect-video object-cover"
                    src={`${process.env.REACT_APP_API_URL}/assets/images/${data.couple_id}/${data.photos}`}
                  />
                </FadeUp>
              </div>
            ))
          ) : (
            <div>Loading</div>
          )}
        </Image.PreviewGroup>
      </div>
    </div>
  );
};

export default Gallery;
