import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { FadeUp } from '../../../components/GeneralComponents/Animation';

const Story = ({ coupleId }) => {
  const [stories, setStories] = useState([]);

  const loadStory = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/couple/story/${id}`
      );

      const { data } = response.data;
      setStories(data);
    } catch (error) {
      console.log(`Couple story error on ${error}`);
    }
  };

  useEffect(() => {
    loadStory(coupleId);
  }, [coupleId]);

  return (
    <div>
      <FadeUp>
        <div className="container mx-auto text-center">
          <h2 className="font-sacramento mx-5 lg:mx-0 text-5xl mb-5 md:text-6xl lg:text-7xl font-extrabold">
            Cerita Kami
          </h2>
        </div>

        <div className="flex justify-center mb-5">
          <img
            src={
              stories.length === 0
                ? null
                : `${process.env.REACT_APP_API_URL}/assets/images/${coupleId}/${stories[0].picture}`
            }
            alt="Mempelai Wanita"
            className="lg:w-1/3 w-4/5"
          />
        </div>

        <div>
          {stories.length !== 0 ? (
            stories.map((story, index) => (
              <ul className="text-center" key={index}>
                <li className="mb-10 lg:px-32 md:px-24 px-10">
                  <h3 className="uppercase text-xl font-bold">{story.title}</h3>
                  <small className="font-bold">{story.timeline}</small>
                  <div className="border-b-2 opacity-50 mx-auto border-gray-400 my-4 w-1/2"></div>
                  <p className="lg:text-base text-sm leading-6 lg:leading-7 font-medium">
                    {story.description}
                  </p>
                </li>
              </ul>
            ))
          ) : (
            <div className="">Loading ...</div>
          )}
        </div>
      </FadeUp>
    </div>
  );
};

export default Story;
