import React from 'react';
import prevention2 from '../../assets/images/health-protocol.png';

const CoronaPrevention = ({ language }) => {
  return (
    <div className="font-dosis text-center font-bold lg:px-0 md:px-2 px-3">
      <h1 className="lg:text-3xl md:text-2xl text-xl">
        {language === 'en'
          ? 'This event will be held with coronavirus prevention protocols'
          : 'Acara ini akan diadakan dengan menerapkan protokol kesehatan'}
      </h1>
      <img src={prevention2} alt="corona-prevention" className="my-10" />
      <h1 className="lg:text-2xl text-xl">
        {language === 'en'
          ? 'Thank you for your cooperation'
          : 'Terima kasih atas kerjasamanya'}
      </h1>
    </div>
  );
};

export default CoronaPrevention;
