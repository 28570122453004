import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Loading from '../../../components/GeneralComponents/Loading';
import Story from './Story';
import Hero from './Hero';
import WeddingInfo from './WeddingInfo';
import Countdown from './Countdown';
import Couple from './Couple';
import GuestBook from '../../../components/GeneralComponents/GuestBook';
import CoronaPrevention from '../../../components/GeneralComponents/CoronaPrevention';
import Quotes from '../../../components/GeneralComponents/Quotes';
import Gallery from '../../../components/GeneralComponents/Gallery';
import Gifts from '../../../components/GeneralComponents/Gifts';
import Music from '../../../components/GeneralComponents/Audio';
import {
  FadeDown,
  FadeLeft,
  FadeRight,
  FadeUpLeft,
  FadeUpRight,
} from '../../../components/GeneralComponents/Animation';

const ThemeThree = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');

  const resultRef = useRef(null);
  const [coupleDetails, setCoupleDetails] = useState({});
  const [musicAudio, setMusicAudio] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHidden, setIsHidden] = useState(true);

  const queryParams = new URLSearchParams(window.location.search);
  const guest = queryParams.get('to');
  const rootElement = document.querySelector(':root');

  // ==========================
  // Start Data From Customers
  // ==========================

  const coupleId = 4;
  const audioId = 1;
  const language = 'en';
  const [youtubeID] = useState('SvXXJsFh7L4');

  const giftAccepted = [
    {
      id: 1,
      bank: 'Jago',
      name: 'Peter Parker',
      accountNo: 123456789,
    },
    {
      id: 2,
      bank: 'BSI',
      name: 'Mary Jane Watson',
      accountNo: 987654321,
    },
  ];

  const giftAddress = {
    receiver: 'Mary Jane Watson',
    address: 'Boulevard St No.41, New York',
  };

  const quotes = {
    word: '“In the arithmetic of love, one plus one equals everything, and two minus one equals nothing.”',
    author: '—Mignon McLaughlin',
  };

  const weddingInfo = {
    akadTime: '08.00 - 10.00',
    akadDate: 15,
    akadMonth: 'December',
    akadYear: 2023,
    receptionTime: '13.30 - 18.00',
    akadDay: 'Sabtu',
  };

  // ==========================
  // End Data From Customers
  // ==========================

  // ==========================
  // Start Handle Click
  // ==========================

  const enableScroll = () => {
    window.onscroll = function () {};
    resultRef.current?.scrollIntoView({ behavior: 'smooth' });
    setIsHidden(false);
    setIsPlaying(true);
    musicAudio.play();
  };

  const handlePlay = () => {
    if (isPlaying) {
      musicAudio.pause();
    } else {
      musicAudio.play();
    }
    setIsPlaying(!isPlaying);
  };

  // ==========================
  // End Handle Click
  // ==========================

  // ==========================
  // Start Function
  // ==========================

  const loadAudio = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/audio/${audioId}`
      );

      const { data } = response.data;

      setMusicAudio(
        new Audio(`${process.env.REACT_APP_API_URL}/assets/audio/${data.audio}`)
      );
    } catch (error) {
      console.log(`Audio error on ${error}`);
    }
  };

  const loadCoupleDetails = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/couple/details/${id}`
      );

      const { data } = response.data;
      setCoupleDetails(data);
    } catch (error) {
      console.log(`Couple details error on ${error}`);
    }
  };

  const disableScroll = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
    window.onscroll = function () {
      window.scrollTo(scrollTop, scrollLeft);
    };
    rootElement.style.scrollBehavior = 'auto';
  };

  // ==========================
  // End Function
  // ==========================

  useEffect(() => {
    disableScroll();
    loadCoupleDetails(coupleId);
    loadAudio();
    // eslint-disable-next-line
  }, []);

  return Object.keys(coupleDetails).length === 0 ? (
    <Loading />
  ) : (
    <div className="font-hubbali z-0 bg-cover bg-fixed text-center thirdTheme">
      <section className=" bg-third-theme bg-cover bg-fixed h-screen w-full flex justify-center items-center py-10">
        <Hero
          coupleDetails={coupleDetails}
          handleClick={() => enableScroll()}
          guest={guest}
        />
      </section>

      <section
        ref={resultRef}
        className=" bg-third-theme bg-cover bg-fixed w-full bg-no-repeat h-fit flex justify-center items-center py-10"
      >
        <Couple details={coupleDetails} />
      </section>

      <section className=" bg-third-theme bg-cover bg-fixed h-fit w-full flex justify-center items-center py-10">
        <FadeDown>
          <Countdown coupleDetails={coupleDetails} />
        </FadeDown>
      </section>

      <section className=" bg-third-theme bg-cover bg-fixed h-fit w-full flex justify-center items-center py-10">
        <WeddingInfo
          type={type}
          details={coupleDetails}
          weddingInfo={weddingInfo}
        />
      </section>

      <section
        className={` bg-third-theme bg-cover bg-fixed h-fit w-full py-10 bg-no-repeat ${
          type === 'basic' ? 'hidden' : ''
        }`}
      >
        <FadeDown>
          <h1 className="text-4xl font-borel pb-10">Guest Book</h1>
          <GuestBook
            guest={guest}
            coupleId={coupleId}
            language={language}
            time={coupleDetails.countdown}
          />
        </FadeDown>
      </section>

      <section className=" bg-third-theme bg-cover bg-fixed h-fit w-full flex justify-center items-center py-10">
        <Quotes arab={false} quotes={quotes} />
      </section>

      <section
        className={`${
          type === 'basic' ? 'hidden' : ''
        }  bg-third-theme bg-cover bg-fixed h-fit w-full py-10`}
      >
        <FadeRight>
          <h1 className="text-4xl font-borel pb-10">Our Story</h1>
          <Story coupleId={coupleId} />
        </FadeRight>
      </section>

      <section className=" bg-third-theme bg-cover bg-fixed ">
        <FadeLeft>
          <h1 className="text-4xl font-borel pb-10">Our Memory</h1>
          <Gallery type={type} coupleId={coupleId} youtubeID={youtubeID} />
        </FadeLeft>
      </section>

      <section className=" bg-third-theme bg-cover bg-fixed py-10">
        <FadeUpRight>
          <h1 className="text-4xl font-borel pb-10">Send Gift</h1>
          <Gifts
            language={language}
            giftAccepted={giftAccepted}
            giftAddress={giftAddress}
          />
        </FadeUpRight>
      </section>

      <section className=" bg-third-theme bg-cover bg-fixed h-screen w-full flex justify-center items-center py-10">
        <FadeUpLeft>
          <CoronaPrevention language={language} />
        </FadeUpLeft>
      </section>

      <Music
        handlePlay={handlePlay}
        isPlaying={isPlaying}
        isHidden={isHidden}
      />
    </div>
  );
};

export default ThemeThree;
