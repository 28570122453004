import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Hero from '../../Theme/ThemeOne/Hero';
import Couple from '../../Theme/ThemeOne/Bride';
import Story from '../../Theme/ThemeOne/Story';
import WeddingInfo from '../../Theme/ThemeOne/WeddingInfo';
import Prevention from '../../../components/GeneralComponents/CoronaPrevention';
import Gifts from '../../../components/GeneralComponents/Gifts';
import Quotes from '../../../components/GeneralComponents/Quotes';
import Music from '../../../components/GeneralComponents/Audio';
import Loading from '../../../components/GeneralComponents/Loading';
import Rsvp from '../../Theme/ThemeOne/Rsvp';
import Greeting from '../../Theme/ThemeOne/Greeting';

const JessyWahyu = () => {
  const resultRef = useRef(null);
  const [coupleDetails, setCoupleDetails] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [musicAudio, setMusicAudio] = useState('');

  const queryParams = new URLSearchParams(window.location.search);
  const guest = queryParams.get('to');
  const rootElement = document.querySelector(':root');

  // ==========================
  // Start Data From Customers
  // ==========================

  const coupleId = 1;
  const audioId = 1;

  const giftAccepted = [
    {
      id: 1,
      bank: 'BCA',
      name: 'WAHYU NUR WAHID',
      accountNo: 3880606564,
    },
    {
      id: 2,
      bank: 'Mandiri',
      name: 'JESSY SYARASTIKA',
      accountNo: 1650002832807,
    },
  ];

  const giftAddress = {
    receiver: 'Wahyu Nur Wahid',
    address:
      'Jl. Kastpii RT 3 RW 3, Ds. Klanting, Kec. Sukodono, Kab. Lumajang, Jawa Timur',
  };

  const quotes = {
    word: `“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan
    pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung
    dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa
    kasih dan sayang. Sungguh, pada yang demikian itu benar-benar
    terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”`,
    author: '—Q.S Ar-Rum:21',
    arabWord: `            وَمِنْ اٰيٰتِهٖٓ اَنْ خَلَقَ لَكُمْ مِّنْ اَنْفُسِكُمْ اَزْوَاجًا
    لِّتَسْكُنُوْٓا اِلَيْهَا وَجَعَلَ بَيْنَكُمْ مَّوَدَّةً وَّرَحْمَةً
    ۗاِنَّ فِيْ ذٰلِكَ لَاٰيٰتٍ لِّقَوْمٍ يَّتَفَكَّرُوْنَ`,
  };

  const weddingInfo = {
    akadTime: '08.00 - 10.00',
    akadDate: 15,
    akadDay: 'Sabtu',
    akadMonth: 'Desember',
    akadYear: 2023,
    receptionTime: '13.30 - 18.00',
    akadPlace: 'Rumah Kediaman Mempelai Wanita',
    receptionPlace: 'Rumah Kediaman Mempelai Wanita',
    maps: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d939.7785026245567!2d102.18912416375014!3d-3.4368593263016156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e315b9462b8b755%3A0x6483b9b66541b556!2sJl.%20RA.%20Kartini%20No.6%2C%20Air%20Merah%2C%20Kec.%20Arga%20Makmur%2C%20Kabupaten%20Bengkulu%20Utara%2C%20Bengkulu%2038611!5e0!3m2!1sen!2sid!4v1697119685340!5m2!1sen!2sid',
  };

  // ==========================
  // End Data From Customers
  // ==========================

  // ==========================
  // Start Handle Click
  // ==========================

  const enableScroll = () => {
    window.onscroll = function () {};
    resultRef.current?.scrollIntoView({ behavior: 'smooth' });
    setIsPlaying(true);
    musicAudio.play();
  };

  const handlePlay = () => {
    if (isPlaying) {
      musicAudio.pause();
    } else {
      musicAudio.play();
    }
    setIsPlaying(!isPlaying);
  };

  // ==========================
  // End Handle Click
  // ==========================

  // ==========================
  // Start Function
  // ==========================

  const loadAudio = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/audio/${audioId}`
      );

      const { data } = response.data;

      setMusicAudio(
        new Audio(`${process.env.REACT_APP_API_URL}/assets/audio/${data.audio}`)
      );
    } catch (error) {
      console.log(`Audio error on ${error}`);
    }
  };

  const loadCoupleDetails = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/couple/details/${id}`
      );

      const { data } = response.data;
      setCoupleDetails(data);
    } catch (error) {
      console.log(`Couple details error on ${error}`);
    }
  };

  const disableScroll = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const scrollLeft = window.scrollX || document.documentElement.scrollLeft;

    window.onscroll = function () {
      window.scrollTo(scrollTop, scrollLeft);
    };

    rootElement.style.scrollBehavior = 'auto';
  };

  // ==========================
  // End Function
  // ==========================

  useEffect(() => {
    disableScroll();
    loadCoupleDetails(coupleId);
    loadAudio();
    // eslint-disable-next-line
  }, []);

  return Object.keys(coupleDetails).length === 0 ? (
    <Loading />
  ) : (
    <div>
      <section className="w-full h-screen bg-first-theme p-3 mx-auto text-center flex justify-center items-center bg-cover text-gray-500 drop-shadow-xl">
        <Hero
          coupleDetails={coupleDetails}
          handleClick={() => enableScroll()}
          guest={guest}
        />
      </section>

      <section className="lg:py-40 py-28 bg-first-theme-2 bg-cover text-gray-500">
        <Quotes ref={resultRef} arab={true} quotes={quotes} />
      </section>

      <section className="lg:py-40 py-28 bg-first-theme-2 bg-cover text-gray-500">
        <Couple details={coupleDetails} />
      </section>

      <section className="lg:py-40 py-28 bg-first-theme-2 bg-cover text-gray-500">
        <WeddingInfo details={coupleDetails} weddingInfo={weddingInfo} />
      </section>

      <section className="lg:py-40 py-28 bg-first-theme-2 bg-cover text-gray-500">
        <Story coupleId={coupleId} />
      </section>

      <section className="lg:py-40 py-28 bg-first-theme-2 bg-cover text-gray-500">
        <Rsvp guest={guest} couple={1} />
      </section>

      <section className="lg:py-40 py-28 bg-first-theme-2 bg-cover text-gray-500">
        <h2 className="font-sacramento text-center mx-5 mb-3 lg:mx-0 text-6xl lg:text-7xl font-extrabold">
          Kirim Hadiah
        </h2>
        <Gifts giftAccepted={giftAccepted} giftAddress={giftAddress} />
      </section>

      <section className="lg:py-40 py-28 bg-first-theme-2 bg-cover text-gray-500">
        <Greeting guest={guest} couple={1} coupleDetails={coupleDetails} />
      </section>

      <section className="lg:py-40 py-28 bg-first-theme-2 bg-cover flex justify-center items-center">
        <Prevention />
      </section>

      <Music handlePlay={handlePlay} isPlaying={isPlaying} />
    </div>
  );
};

export default JessyWahyu;
