import React from 'react';
import { socialMedia } from '../../utils/constants';
import { Icon } from '@iconify/react';

const Footer = () => {
  return (
    <footer className="my-2">
      <div className="col text-center">
        <small className="block">
          &copy; 2023 Infinite Invites. All Rights Reserved
        </small>
        <small className="block">
          Made with{' '}
          <Icon className="text-pink-600 inline-block" icon="ph:heart-fill" />{' '}
          by
          <a
            rel="noreferrer"
            target="_blank"
            className="font-black cursor-pointer hover:underline"
            href="http://instagram.com/infiniteinvites"
          >
            {' '}
            @infiniteinvites
          </a>
          .
        </small>

        <div className="flex justify-center">
          {socialMedia.map((social, index) => {
            return (
              <p key={index} className="px-5 text-lg">
                <a href={social.link} target="_blank" rel="noreferrer">
                  {social.icon}
                </a>
              </p>
            );
          })}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
