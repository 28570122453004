import React, { useState } from 'react';
import Logo from '../../assets/images/logo.png';
import {
  NavLink as LinkRouter,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { Link as LinkScroll } from 'react-scroll';
import * as Scroll from 'react-scroll';
import Sidebar from './Sidebar.js';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const path = useLocation().pathname;
  const location = path.split('/')[1];
  const navigate = useNavigate();
  const scroller = Scroll.scroller;

  const goToPageAndScroll = async (selector) => {
    await navigate('/');
    await scroller.scrollTo(selector, {
      duration: 500,
      smooth: true,
      offset: -75,
      spy: true,
    });
  };

  return (
    <nav className="flex bg-[#d9d9d9] flex-wrap place-items-center z-10 shadow-md sticky top-0 left-0">
      <Sidebar
        isOpen={isOpen}
        goToPageAndScroll={goToPageAndScroll}
        setIsOpen={setIsOpen}
        path={path}
        location={location}
      />
      <div className="relative mx-auto">
        {/*  Navbar  */}
        <div className="flex justify-between w-screen">
          <div className="xl:px-12 ml-5 py-3 flex w-full items-center">
            <LinkRouter to="/">
              <img src={Logo} className="w-20 h-14" alt="" />
            </LinkRouter>

            {/*  Nav Links  */}
            <ul className="hidden lg:flex mx-auto font-semibold font-heading space-x-12">
              {location !== 'portfolio' && location !== 'catalogue' ? (
                <>
                  <li className="my-7 link-underline link-underline-black text-black md:my-0 font-semibold md:ml-8 cursor-pointer">
                    {' '}
                    <LinkScroll
                      to="home"
                      spy={true}
                      smooth={true}
                      offset={-75}
                      duration={500}
                    >
                      Home
                    </LinkScroll>
                  </li>
                  <li className="my-7  link-underline link-underline-black text-black md:my-0 font-semibold md:ml-8 cursor-pointer">
                    <LinkScroll
                      to="whyus"
                      spy={true}
                      smooth={true}
                      offset={-75}
                      duration={500}
                    >
                      Kenapa Kami
                    </LinkScroll>
                  </li>
                  <li className="my-7  link-underline link-underline-black text-black md:my-0 font-semibold md:ml-8 cursor-pointer">
                    <LinkScroll
                      to="feature"
                      spy={true}
                      smooth={true}
                      offset={-75}
                      duration={500}
                    >
                      Fitur
                    </LinkScroll>
                  </li>
                  <li className="my-7  link-underline link-underline-black text-black md:my-0 font-semibold md:ml-8 cursor-pointer">
                    <LinkScroll
                      to="price"
                      spy={true}
                      smooth={true}
                      offset={-75}
                      duration={500}
                    >
                      Harga
                    </LinkScroll>
                  </li>
                  <li className="my-7  link-underline link-underline-black text-black md:my-0 font-semibold md:ml-8 cursor-pointer">
                    <LinkRouter to="/catalogue">Katalog</LinkRouter>
                  </li>
                  <li className="my-7  link-underline link-underline-black text-black md:my-0 font-semibold md:ml-8 cursor-pointer">
                    <LinkRouter to="/portfolio">Portofolio</LinkRouter>
                  </li>
                </>
              ) : (
                <>
                  <li className="my-7 link-underline link-underline-black text-black md:my-0 font-semibold md:ml-8 cursor-pointer">
                    {' '}
                    <button
                      className=""
                      onClick={() => goToPageAndScroll('home')}
                    >
                      Home
                    </button>
                  </li>
                  <li className="my-7 link-underline link-underline-black text-black md:my-0 font-semibold md:ml-8 cursor-pointer">
                    <button
                      className=""
                      onClick={() => goToPageAndScroll('whyus')}
                    >
                      Kenapa Kami
                    </button>
                  </li>
                  <li className="my-7 link-underline link-underline-black text-black md:my-0 font-semibold md:ml-8 cursor-pointer">
                    <button
                      className=""
                      onClick={() => goToPageAndScroll('feature')}
                    >
                      Fitur
                    </button>
                  </li>
                  <li className="my-7 link-underline link-underline-black text-black md:my-0 font-semibold md:ml-8 cursor-pointer">
                    <button
                      className=""
                      onClick={() => goToPageAndScroll('price')}
                    >
                      Harga
                    </button>
                  </li>
                  <li className="my-7  link-underline link-underline-black text-black md:my-0 font-semibold md:ml-8 cursor-pointer">
                    <LinkRouter to="/catalogue">Katalog</LinkRouter>
                  </li>
                  <li className="my-7  link-underline link-underline-black text-black md:my-0 font-semibold md:ml-8 cursor-pointer">
                    <LinkRouter to="/portfolio">Portofolio</LinkRouter>
                  </li>
                </>
              )}
            </ul>

            {/*  Header Icons  */}
            {/* <div className="hidden xl:flex space-x-5 items-center">
                <button>Hubungi kami</button>
              </div> */}
          </div>

          {/*  Responsive navbar  */}
          <div className="self-center mr-5 lg:hidden">
            <button onClick={() => setIsOpen(!isOpen)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 hover:text-gray-200"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
