import { InstagramOutlined, WhatsAppOutlined } from '@ant-design/icons'

export const weddingLinks = [
  {
    id: 1,
    text: 'Home',
    url: '/home',
  },
  {
    id: 2,
    text: 'Info',
    url: '/info',
  },
  {
    id: 3,
    text: 'story',
    url: '/story',
  },
  {
    id: 4,
    text: 'RSVP',
    url: '/rsvp',
  },
  {
    id: 5,
    text: 'Gifts',
    url: '/gifts',
  },
]

export const socialMedia = [
  {
    id: 1,
    icon: <InstagramOutlined />,
    link: 'https://www.instagram.com/infiniteinvites/',
  },
  {
    id: 1,
    icon: <WhatsAppOutlined />,
    link: 'https://wa.me/62812345566788',
  },
]
